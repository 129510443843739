import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Button, Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axios from "axios";


const LoginForm = () => {
    const navigate = useNavigate();
    const [data, setdata] = useState({ email: "", password: "" });
    const [emailErr, setEmailErr] = useState("");
    const [passwordErr, setPasswordErr] = useState("");
    const [Msg, setMsg] = useState("");
    const signIntUrl = process.env.React_App_API_URL + '/admin/adminSignIn';

    const postData = () => {
        axios.post(signIntUrl,
            {
                email: data.email,
                password: data.password
            })
            .then((response) => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('email', response.data.admin.email);
                localStorage.setItem('adminID', response.data.admin._id);
                navigate('/admin/dashboard');
            })
            .catch(error => {
                console.log(error);
                let err = error.response.data.errors;
                let message = error.response.data.message;
                setMsg(message);
                console.log("message", message);
                console.log(err);

                err.filter((val) => {
                    if (val.param === "email") {
                        setEmailErr(err[0].msg);
                    }
                    else if (val.param === "password") {
                        setPasswordErr(val.msg);
                    }
                })
            })
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            postData();
        }
    }

    const handleChange = (event) => {
        let name = event.target.name;
        let val = event.target.value;
        setdata({
            ...data,
            [name]: val,
        })
    }

    const ifLoggedIn = () => {
        const email = localStorage.getItem('email');
        if (email) {
            navigate('/admin/dashboard');
        }
    }
    useEffect(() => {
    }, [data]);

    useEffect(() => {
        ifLoggedIn();
    }, []);


    const handleSubmit = (event) => {
        postData();
    }


    return (

        <div className="bg-gradient-primary">
            <Button color="info mt-3 ml-3" onClick={()=>navigate('/')}> User Login </Button>
            <div id="login">
                <div class="container">
                    <div id="login-row" class="row justify-content-center align-items-center">
                        <div id="login-column" class="col-md-6">
                            <div id="login-box" class="col-md-12">
                                <Form>
                                    <h3 class="text-center text-info">Admin Login</h3>

                                    <FormGroup>
                                        <Label for="email">
                                            <strong>Email: </strong> </Label>
                                        <Input type="email" name="email" placeholder="E-mail" onKeyDown={handleKeyDown} onChange={handleChange} />
                                        {emailErr && <span style={{ color: "red" }}>{emailErr}</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="password">
                                            <strong>Password: </strong> </Label>
                                        <Input type="password" name="password" placeholder="password" onKeyDown={handleKeyDown} onChange={handleChange} />
                                        {
                                            passwordErr && <span style={{ color: "red" }}>{passwordErr}</span>

                                        }</FormGroup>

                                    <p style={{ color: "red" }}> {Msg} </p>
                                </Form>
                                <Button type="submit" className="btn btn-primary btn-user btn-block" value="Submit" color='primary' onClick={handleSubmit}>
                                    Login
                                </Button>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default LoginForm;







