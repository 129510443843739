import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import { Table, Button, Form, Input } from 'reactstrap';
import NavbarTemplate from "../navbar";
import CreateBundle from "./create";
import EditBundle from './edit';
import ReactPaginate from 'react-paginate';
import LogOut from "../logout";

const Bundle = () => {

  // useState
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [editId, setEditId] = useState(0);
  const [totalPages, setPages] = useState(0);
  const [searchText, setsearchText] = useState("");
  const [displayList, setDisplayList] = useState([]);
  const [sendPage, setSendPage] = useState(0);
  const [createdBy, setcreatedBy] = useState("");
  const [selectContent, setSelectContent] = useState("");
  const email = localStorage.getItem('email');
  const [data, setdata] = useState({ name: "", create: createdBy, desc: "", max: 0, content: selectContent });
  const adminID = localStorage.getItem('adminID');
  const [productData, setProductData] = useState([]);
  const contentOptions = [];
  const createdOptions = [{ label: email, value: adminID }];
  const [errMax, setErrMax] = useState("");
  const [errContent, setErrContent] = useState("");
  const [errCreate, setErrCreate] = useState("");
  const [errDesc, setErrDesc] = useState("");
  const [errName, setErrName] = useState("");
  const [message, setMessage] = useState("");
  const createArray = [];
  const getProductUrL = process.env.React_App_API_URL + '/product/getAllProduct?limit=1000';
  const makeBundleUrl = process.env.React_App_API_URL + '/bundle/createBundle';

  //functions
  const getData = (currentPage) => {
    let bundleUrl = process.env.React_App_API_URL + `/bundle/getAllBundle?limit=5&page=${currentPage}&search=${searchText}`;
    axios.get(bundleUrl, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(function (response) {
      console.log("res", response);
      let data = response.data.data;
      console.log("data", data);
      setDisplayList(data);
      setPages(response.data.totalPages);
    })
  }

  const onDelete = (id) => {
    console.log("delid", id);
    axios.post(process.env.React_App_API_URL + `/bundle/deleteBundle//${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        if(sendPage== 0)
        {
            getData(sendPage +1);
        }
        else{getData(sendPage);
        }
      })
      .catch(function (error) {
        console.log(error);
        console.log(sendPage, "pageno");
        if(sendPage== 0)
        {
            getData(sendPage +1);
        }
        else{getData(sendPage);
        }
      });
  }

  const handleEdit = (id) => {
    localStorage.setItem('bundle_id', id);
    setEditId(id);
  }

  const handleDelete = (id) => {
    onDelete(id);
  }

  const searchHandler = (e) => {
    var lowerCase = e.target.value;
    setsearchText(lowerCase);

  }
  const isLoggedIn = () => {
    const email = localStorage.getItem('email');
    if (email === null) {
      navigate('/admin/login');
    }
  }

  useEffect(() => {
    isLoggedIn();
  }, [])

  const handlePageClick = (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    console.log(currentPage);
    setSendPage(currentPage);
    const pagesFromServer = getData(currentPage);
    console.log(pagesFromServer, "pagesFromServer");
  }

  const handleChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    data.create = createdBy;
    data.content = selectContent;
    data.content = selectContent;
    setdata({
      ...data,
      create: createdBy,
      content: selectContent
    })
    setdata({
      ...data,
      [name]: val,
    })
  }

  const getProduct = () => {
    axios.get(getProductUrL, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(function (response) {
      let data = response.data.data;
      setProductData(data);
    })
  }

  const handleSubmit = () => {
    postData();
  }

  //to create bundle
  const postData = () => {

    console.log("createdBy", createdBy);
    console.log("Bundle", data);

    let params = {
      bundleName: data.name,
      createdBy: createdBy,
      maxPurchase: data.max,
      content: selectContent,
      description: data.desc
    }

    axios.post(makeBundleUrl,
      params, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          setdata({ name: "", create: createdBy, desc: "", max: 0, content: "" });
          setcreatedBy("");
          setSelectContent("");
        }
        getData();
      })
      .catch(error => {
        console.log(error);
        let err = error.response.data.errors;
        console.log(err, "err");

        err.filter((val) => {
          if (val.param === "bundleName") {
            setErrName(val.msg);
          }
          if (val.param === "description") {
            setErrDesc(val.msg);
          }
          if (val.param === "createdBy") {
            setErrCreate(val.msg);
          }
          if (val.param === "content") {
            setErrContent(val.msg);
          }
          if (val.param === "maxPurchase") {
            setErrMax(val.msg);
          }
        })

      })
  }

  function handleContent(e) {
    let x = e.map((val) => {
      return (val.value
      )
    })
    console.log(x, "x");
    setSelectContent(x);
    setdata({
      ...data,
      content: x
    })
    console.log("multiSelect", e);
  }

  useEffect(() => {
    getProduct();
  }, [data]);

  productData.map((info) => {
    contentOptions.push({
      label: info.productName,
      value: info._id
    }
    )
    return true;
  }
  )
  useEffect(() => {
    getData();
  }, [searchText]);

  const handleCreateBy = (e) => {
    console.log("create in func", e.value);
    createArray.push(e.value);
    setcreatedBy(createArray);
    setdata({
      ...data,
      create: createArray
    })
  }

  return (
    <>
      {/* body */}

      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
          {/* <!-- Sidebar - Brand --> */}
          <Link to="/admin/dashboard" className="sidebar-brand d-flex align-items-center justify-content-center" >
            <div className="sidebar-brand-icon rotate-n-15">
              <i className="fas fa-laugh-wink"></i>
            </div>
            <div className="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
          </Link>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider my-0" />
          {/* <!-- Nav Item - Dashboard --> */}
          <li className="nav-item active">
            <Link to="/admin/dashboard" className="nav-link" >
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span></Link>
          </li>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider" />
          {/* <!-- Heading --> */}
          <div className="sidebar-heading">Bundle Page </div>
          <li className="nav-item">
            <Link to="/admin/product" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Products Page</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/bundle" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Bundle Page</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/campaign" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Campaign Page</span>
            </Link>
          </li>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider" />
        </ul>
        {/* <!-- End of Sidebar --> */}
        {/* <!-- Content Wrapper --> */}

        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              {/* <!-- Sidebar Toggle (Topbar) --> */}
              <Button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars"></i>
              </Button>
              {/* <!-- Topbar Search --> */}

              <Form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div className="Input-group">
                  <Input type="text"
                    className="form-control bg-light border-0 small"
                    aria-label="Search" aria-describedby="basic-addon2"
                    id="outlined-basic"
                    onChange={searchHandler}
                    onKeyPress={getData}
                    variant="outlined"
                    placeholder="search for..."
                    label="Search"
                  />
                  <div Name="input-group-append">
                    <Button color="primary" className="btn btn-primary" type="Button" onClick={getData}> <i className="fas fa-search fa-sm"></i>
                    </Button>
                  </div>
                </div>
              </Form>
              <LogOut />
              {/* <!-- Topbar Navbar --> */}
              <ul className="navbar-nav ml-auto">
                <div className="topbar-divider d-none d-sm-block"></div>
                {/* <!-- Nav Item - User Information --> */}
                <li className="nav-item dropdown no-arrow">
                  <Link to="#" className="nav-link dropdown-toggle" id="userDropdown" role="Button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">Douglas McGee</span>
                    <img className="img-profile rounded-circle" src="../img/undraw_profile.svg" />
                  </Link>
                  {/* <!-- Dropdown - User Information --> */}
                  <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                    <Link to="#" className="dropdown-item" >
                      <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      Profile
                    </Link>
                    <Link to="#" className="dropdown-item" >
                      <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                      Settings
                    </Link>
                    <Link to="#" className="dropdown-item" >
                      <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                      Activity Log
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link to="#" className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </Link>
                  </div>
                </li>
              </ul>
            </nav>
            {/* <!-- End of Topbar --> */}
            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <div>
                  {/* <!-- Button to Open the Modal --> */}
                  {/* <Button color="primary" type="Button" className="btn btn-sm btn-primary shadow-sm" data-toggle="modal" data-target="#addProduct"><i className="fas fa-plus fa-sm text-white-50"></i> Add Bundles</Button> */}
               <CreateBundle getData={getData}/>
                </div>
              </div>
              {/* <!-- Content Row --> */}


              <EditBundle handleEdit={handleEdit} id={editId} getData={getData} page={sendPage} />

              {/* <CreateBundle getData={getData} /> */}

              {/* <!-- Content Row --> */}
              <div className="row">
                {/* <!-- Area Chart --> */}
                <div className="col-xl-12">
                  <div className="card shadow mb-4">
                    {/* <!-- Card Header - Dropdown --> */}
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">Bundle list</h6>
                    </div>
                    {/* <!-- Card Body --> */}
                    <div className="card-body">
                      < Table striped hover >

                        <thead>
                          <tr>

                            <th scope="col">Bundle Name</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Max Purchase</th>
                            <th scope="col">Description </th>
                            <th scope="col">Content</th>
                            <th scope="col" width="100" class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>

                          {displayList.length > 0 ?
                            displayList.map((data, index) =>

                              <tr key={index}>
                                <td>{data.bundleName}</td>
                                <td>{data.createdBy.length && data.createdBy.map((itm, inx) =>
                                  <p key={inx}>{itm.email}</p>
                                )} </td>
                                <td>{data.maxPurchase}</td>
                                <td>{data.description}</td>
                                <td><ol type="A">{data.content.length && data.content.map((item, indx) =>
                                  <li key={indx}>{item.productName}</li>
                                )} </ol></td>
                               <button class="btn btn-default btn-sm" onClick={() => { handleEdit(data._id) }}><i class="fas fa-pencil-alt"></i></button>
                                <button class="btn btn-default btn-sm" onClick={() => { handleDelete(data._id) }}><i class="far fa-trash-alt"></i></button>
                           </tr>

                            )
                            :
                            <tr >
                            <td colSpan={6} align="center"><h3>No Record Found</h3></td>
                            </tr>
                          }
                        </tbody>
                      </Table>

                      <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        pageCount={totalPages}
                        breakLabel="..."
                        pageRangedisplayListed={2}
                        renderOnZeroPageCount={null}
                        breakClassName={"page-link"}
                        breakLinkClassName={"pagination-link"}
                        marginPagesdisplayListed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-link"}
                        previousLinkClassName={"pagination__link"}
                        nextClassName={"page-link"}
                        nextLinkClassName={"pagination__link"}
                        subContainerClassName={'pages pagination'}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"active"}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          {/* <!-- Footer --> */}
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright © Your Website 2021</span>
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>

      {/*<!-- The Modal -->*/}
      <div className="modal fade" id="addProduct">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {/* <!-- Modal Header --> */}
            <div className="modal-header">
              <h4 className="modal-title">Add a New Bundle!</h4>
              <Button type="Button" className="close" data-dismiss="modal">&times;</Button>
            </div>

            {/* <!-- Modal body --> */}

            <div className="modal-body">
              <Form className="form">
                {/* <!-- Text Input--> */}
                <div className="row">
                  {/* <!-- Area Chart --> */}
                  <div className="col-4">
                    <div className="form-group">

                      <label className="control-label" for="name">BUNDLE NAME:</label>
                      <Input id="BUNDLE_NAME" type="text" name="name" placeholder="Enter Bundle Name" onChange={handleChange} className="form-control Input-md" required="" />
                      {errName && <span style={{ color: "red" }}>{errName}</span>}
                    </div>
                  </div>

                  <div className="col-4">
                    {/* <!-- Select Basic --> */}
                    <div className="form-group">
                      <label className="control-label" for="create">CREATED BY:</label>
                      <Select id="product_categorie" name="product_categorie" options={createdOptions} onChange={(e) => handleCreateBy(e)} className="form-control">
                      </Select>
                      {errCreate && <span style={{ color: "red" }}>{errCreate}</span>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">

                      <label className="control-label" for="desc">DESCRIPTION:</label>
                      <Input id="description" name="desc" placeholder="Enter Description" onChange={handleChange} className="form-control Input-md" required="" type="text" />
                    </div>
                    {errDesc && <span style={{ color: "red" }}>{errDesc}</span>}
                  </div>
                  <div className="col-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">

                      <label className="control-label" for="max">MAX PURCHASE</label>
                      <Input id="max_purchase" name="max" type="number" placeholder="Enter Max Purchase Number" onChange={handleChange} className="form-control Input-md" required="" />
                      {errMax && <span style={{ color: "red" }}>{errMax}</span>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">

                      <label className="control-label" for="product_description">CONTENT</label>
                      <Select isMulti id="content" name="content" options={contentOptions} onChange={(e) => handleContent(e)} className="form-control">
                      </Select>
                      {errContent && <span style={{ color: "red" }}>{errContent}</span>}
                    </div>
                  </div>
                </div>
                <p style={{ color: "red" }}>{message}</p>
              </Form>
            </div>

            {/* <!-- Modal footer --> */}
            <div className="modal-footer">
              <Button type="Button" className="btn btn-danger" data-dismiss="modal">Close</Button>
              <Button id="singleButton" name="singleButton" color="primary" onClick={handleSubmit} className="btn btn-primary">submit</Button>
            </div>
          </div>
        </div>
      </div>


    </>
  );

}
export default Bundle;







