import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Form, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const EditBundle = (props) => {
    //useState
    const adminID = localStorage.getItem('adminID');
    const email = localStorage.getItem('email');
    const [createdBy, setCreatedBy] = useState([]);
    const [selectContent, setSelectContent] = useState({ label: "select", value: "" });
    const createdByOptions = [{ value: adminID, label: email }];
    const [bundleData, setbundleData] = useState({ name: "", create: createdBy, desc: "", max: 0, content: selectContent });
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [editModal, setEditModal] = useState(false);
    const [errContent, setErrContent] = useState("");
    const [errCreate, setErrCreate] = useState("");
    const [errDesc, setErrDesc] = useState("");
    const [errName, setErrName] = useState("");
    const [message, setMessage] = useState("");
    const [errMax, setErrMax] = useState("");
    const ID = localStorage.getItem('bundle_id');
    const [Product_list, setProduct_list] = useState([]);
    const contentArray = [];
    const bundleUrl = process.env.React_App_API_URL+`/bundle/getAllBundle?limit=1000`;
    const productUrl = process.env.React_App_API_URL+`/product/getAllProduct?limit=1000`;

    //functions
    const edittoggle = () => {
        setEditModal(!editModal);
        if (editModal === true) {
            props.handleEdit();
        }
        if (editModal === false) {
            setErrMax("");
            setErrCreate("");
            setErrName("");
            setErrDesc("");
            setMessage("");
            setErrContent("");
            
        }
    }

    const setID = () => {
        props.handleEdit("");
    }

    const onEdit = () => {
        axios.get(bundleUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(function (response) {
            let output = response.data.data;
            console.log("data", output);
            setSelectContent([]);
            //filter by id         
            if (output.filter(data => data._id === props.id).length !== 0) {
                output.filter(data => data._id === props.id).map(val => {
                    console.log(val, "val");
                    bundleData.name = val.bundleName;
                    var xyz = val.createdBy.length && val.createdBy.map((itm, inx) =>
                        setCreatedBy({
                            label: itm.email,
                            value: itm._id
                        }));

                    var contents = val.content.length && val.content.map((itm, inx) => { return ({ label: itm.productName, value: itm._id }) }
                    );
                    setSelectContent(contents);
                    bundleData.create = val.createdBy;
                    bundleData.max = val.maxPurchase;
                    bundleData.content = val.content;
                    bundleData.desc = val.description
                    return true;
                })
                edittoggle();
            }
        })
    }

    const editHandler = (event) => {
        let name = event.target.name;
        let val = event.target.value;
        if (createdBy.value) {
            bundleData.create = createdBy.value;
        }

        bundleData.create = createdBy;
        setbundleData({
            ...bundleData,
            create: createdBy,
        })
        setbundleData({
            ...bundleData,
            [name]: val,
        })
    }

    const getProduct = () => {
        axios.get(productUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(function (response) {
            let product_list = response.data.data;
            let newArray = [];
            product_list.map((info, index) => {
                newArray.push({
                    label: info.productName,
                    value: info._id
                }
                )
                return setProduct_list(newArray);
            }
            )
        })
    }

    const handleSubmit = () => {
        console.log(selectContent, "select");
        let final = selectContent.map((val) => {
            if (val.value === undefined) {
                return val;
            }
            else {
                return val.value;
            }
        })
        let data = {
            bundleName: bundleData.name,
            createdBy: [createdBy.value],
            maxPurchase: bundleData.max,
            description: bundleData.desc,
            content: final
        }

        let config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }

        axios.post(process.env.React_App_API_URL+`/bundle/updateBundle/${ID}`, data, config)
            .then((response) => {
                if (props.page == 0) {
                    props.getData(props.page + 1);
                }
                props.getData(props.page);
                console.log(bundleData, createdBy);
                edittoggle();
                setID();

            })
            .catch(error => {
                console.log(error);
                props.getData();
                let err = error.response.data.errors;
                console.log(err, "err");

                err.filter((val) => {
                    if (val.param === "bundleName") {
                        setErrName(val.msg);
                    }
                    else {
                        setMessage(val.msg);
                    }
                    if (val.param === "description") {
                        setErrDesc(val.msg);
                    }
                    if (val.param === "createdBy") {
                        setErrCreate(val.msg);
                    }
                    if (val.param === "content") {
                        setErrContent(val.msg);
                    }
                    if (val.param === "maxPurchase") {
                        setErrMax(val.msg);
                    }
                })
            })
    }

    function handleContent(e) {
        console.log("Product_list", Product_list);
        setSelectContent(e)
        let x = e.map((val) => {
            if (val.value === undefined) {
                return val;
            }
            else {
                return val.value;
            }
        })
        console.log(x, "x");
        setSelectContent(x);
        setbundleData({
            ...bundleData,
            content: x
        })
        contentArray.push({
            e, x
        }
        )
        console.log("contentArray", contentArray);
    }

    const isLoggedIn = () => {
        const email = localStorage.getItem('email');
        if (email === null) {
            navigate('/admin/login');
        }
    }

    useEffect(() => {
        onEdit(props.id);
    }, [props.id]);

    useEffect(() => {
        getProduct();
    }, [bundleData]);

    useEffect(() => {
        isLoggedIn();
    }, [])

    return (

        <div>
            <Modal isOpen={editModal} toggle={edittoggle} >
                <ModalHeader toggle={edittoggle}>Edit Bundle Details!</ModalHeader>
                <ModalBody>
                    <Form className="form">
                {/* <!-- Text Input--> */}
                <div className="row">
                  {/* <!-- Area Chart --> */}
                  <div className="col-4">
                    <div className="form-group">

                      <label className="control-label" for="name">BUNDLE NAME:</label>
                      <Input  id="BUNDLE_NAME" type="text" name="name" className="form-control Input-md" required="" defaultValue={bundleData.name} placeholder="Enter Bundle Name" onChange={editHandler} />
                          
                      {errName && <span style={{ color: "red" }}>{errName}</span>}
                    </div>
                  </div>

                  <div className="col-4">
                    {/* <!-- Select Basic --> */}
                    <div className="form-group">
                      <label className="control-label" for="create">CREATED BY:</label>
                      <Select  id="product_categorie" options={createdByOptions} onChange={(e) => setCreatedBy(e)} defaultValue={createdBy} >
                      </Select>
                      {errCreate && <span style={{ color: "red" }}>{errCreate}</span>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">

                      <label className="control-label" for="desc">DESCRIPTION:</label>
                      <Input name="desc" type="text" placeholder="Enter Description" defaultValue={bundleData.desc} onChange={editHandler} className="form-control Input-md" required=""/>
                      {errDesc && <span style={{ color: "red" }}>{errDesc}</span>}
                    </div>
                 
                  </div>
                  <div className="col-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">

                      <label className="control-label" for="max">MAX PURCHASE</label>
                      <Input name="max" type="number" placeholder="Enter Max Purchase Number" defaultValue={bundleData.max} onChange={editHandler} className="form-control Input-md" required="" />
                      {errMax && <span style={{ color: "red" }}>{errMax}</span>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">

                      <label className="control-label" for="content">CONTENT</label>
                      <Select isMulti id="content" name="content" defaultValue={selectContent} options={Product_list} onChange={(e) => handleContent(e)} >
                      </Select>
                      {errContent && <span style={{ color: "red" }}>{errContent}</span>}
                    </div>
                  </div>
                </div>
                {/* <p style={{ color: "red" }}>{message}</p> */}
                <div className="modal-footer">
              </div>
                       
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button type="Button" onClick={edittoggle} className="btn btn-danger" value="Submit" data-dismiss="modal">Close</Button>
              <Button id="singleButton" name="singleButton" color="primary" onClick={handleSubmit} className="btn btn-primary">submit</Button>
           
                </ModalFooter>
            </Modal>

        </div>

    );


}


export default EditBundle;



