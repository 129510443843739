import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader, Button, Form, Input } from "reactstrap";

const ForgetPswd = (props) => {
        const [forgetEmail, setEmail] = useState("");
        const [modal1, setModal1] = useState(false);
        const [modal2, setModal2] = useState(false);
        const [confirmEmail, setConfirmEmail] = useState("");
        const [confirmPswd, setConfirmPswd] = useState("");
        const [confirmCode, setCode] = useState("");
        const navigate = useNavigate();
        const [Msg1, setMsg1] = useState("");
        const [Msg2, setMsg2] = useState("");
        const [emailErr, setEmailErr] = useState("");

        const toggle1 = () => {
                setModal1(!modal1);
                if (modal1 === false) {
                        setEmailErr("");
                        setMsg1("");
                    }
        }

        const toggle2 = () => {
                setModal2(!modal2);
                if (modal2 === false) {
                        setEmailErr("");
                        setMsg2("");
                    }
        }
        const modal1Data = () => {
                console.log("fgt", forgetEmail)
                axios.post('http://peak-discount-api.imenso.in/users/sendOtp',
                        {
                                email: forgetEmail
                        }, {
                        headers: {
                                Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQDEyMy5jb20iLCJpZCI6IjYzZDM2ZTliNzhlMjczZmVhOGE2YWU4NSIsImlhdCI6MTY4MDUxMDI3MX0.svcUrVkhofeGu1MwJaorz8y7lPMPWFF0NZ1V1oHezDk"}`,
                                'Content-Type': 'application/x-www-form-urlencoded'
                        }
                })
                        .then((response) => {
                                console.log("res", response);
                                if (response.status === 200) {
                                        toggle1();
                                        toggle2();
                                }
                        })
                        .catch(error => {
                                let err = error.response.data.errors;
                                console.log(error);
                                let message = error.response.data.message;
                                setMsg1(message);
                                err.filter((val) => {
                                        if (val.param === "email") {
                                            setEmailErr(err[0].msg);
                                        }})
                        })
        }

        const modal2Data = () => {
                console.log("val", confirmPswd, confirmCode, confirmEmail)
                axios.post('http://peak-discount-api.imenso.in/users/newPassword',
                        {
                                email: confirmEmail,
                                password: confirmPswd,
                                code: confirmCode
                        }, {
                        headers: {
                                Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQDEyMy5jb20iLCJpZCI6IjYzZDM2ZTliNzhlMjczZmVhOGE2YWU4NSIsImlhdCI6MTY4MDUxMDI3MX0.svcUrVkhofeGu1MwJaorz8y7lPMPWFF0NZ1V1oHezDk"}`,
                                'Content-Type': 'application/x-www-form-urlencoded'
                        }
                })
                        .then((response) => {
                                console.log("res", response);
                                if (response.status === 200) {
                                            setMsg2(response.data.message);
                                            toggle2();
                                }
                        })
                        .catch(error => {
                                console.log(error);
                                let message = error.response.data.message;
                                setMsg2(message);
                                
                        })
        }

        return (<>
                <div className="col-md-12 form-group">
                        <Link onClick={toggle1}>Forgot Password?</Link>
                </div>
                {/* Modal 1 */}
                < Modal isOpen={modal1} toggle={toggle1}>
                        <ModalHeader toggle={toggle1}>Enter Email for Verification</ModalHeader>
                        <ModalBody>

                                <Form className="form">
                                        <Label for="email">Email</Label>
                                        <Input type="email" name="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} className="form-control Input-md" required="" />
                                        {emailErr && <span style={{ color: "red" }}>{emailErr}</span>} 
                                        <p style={{ color: "red" }}>{Msg1}</p>
                                </Form>
                        </ModalBody>
                        <ModalFooter>
                                <Button type="Button" className="btn btn-danger" onClick={toggle1} data-dismiss="modal">Close</Button>
                                <Button id="singleButton" name="singleButton" color="primary" onClick={modal1Data} className="btn btn-primary">submit</Button>

                        </ModalFooter>
                </Modal>

                {/* Modal 2 */}
                < Modal isOpen={modal2} toggle={toggle2}>
                        <ModalHeader toggle={toggle2}>Add new Password and Enter Verification Code</ModalHeader>
                        <ModalBody>

                                <Form className="form">
                                        <Label for="email">Email</Label>
                                        <Input type="email" name="email" placeholder="Enter Email" onChange={(e) => setConfirmEmail(e.target.value)} className="form-control Input-md" required="" />
                                        <Label for="pswd">Password</Label>
                                        <Input type="password" name="pswd" placeholder="Enter Password" onChange={(e) => setConfirmPswd(e.target.value)} className="form-control Input-md" required="" />
                                        <Label for="code">Code</Label>
                                        <Input type="text" name="code" placeholder="Enter Code" onChange={(e) => setCode(e.target.value)} className="form-control Input-md" required="" />
                                        <p style={{ color: "red" }}>{Msg2}</p>
                                </Form>
                        </ModalBody>
                        <ModalFooter>
                                <Button type="Button" className="btn btn-danger" onClick={toggle2} data-dismiss="modal">Close</Button>
                                <Button id="singleButton" name="singleButton" color="primary" onClick={modal2Data} className="btn btn-primary">submit</Button>

                        </ModalFooter>
                </Modal>
        </>);


}
export default ForgetPswd;