import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const EditProduct = (props) => {
    //useState
    const [productData, setproductData] = useState({ name: "", type: "", img: [], dets: "", price:0 });
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [errType, setErrType] = useState("");
    const [errImg, setErrImg] = useState("");
    const [errPrice, setErrPrice] = useState("");
    const [errDets, setErrDets] = useState("");
    const [errName, setErrName] = useState("");
    const [editModal, setEditModal] = useState(false);
    const ID = localStorage.getItem('prod_id');
    const productUrl = process.env.React_App_API_URL+`/product/getAllProduct?limit=500`;

    //functions

    function edittoggle() {
        setEditModal(!editModal)
        if (editModal === true) {
            props.handleEdit();
        }
        if(editModal === false) {
            setErrDets("");
            setErrImg("");
            setErrName("");
            setErrType("");
            setErrPrice("");
    }
}

    const setID = () => {
        props.handleEdit("");
    }

    const onEdit = () => {
        axios.get(productUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            let output = response.data.data;
            console.log(222, output);

            //filter by id         
            if (output.filter(data => data._id === props.id).length !== 0) {
                output.filter(data => data._id === props.id).map(val => {
                    productData.name = val.productName;
                    productData.type = val.productType;
                    productData.img = val.productImg;
                    productData.price= val.productPrice;
                    productData.dets = val.prodDetails;
                    return true;
                })
                edittoggle();
            }
            
        }).catch(error => {
            console.log(error);
            if (props.page == 0) {
                props.getData(props.page + 1);
            }
            else{
            props.getData(props.page);
            }
            let err = error.response.data.errors;
            console.log(err, "err");
            console.log(error);
        })

    }

    const editHandler = (event) => {
        let name = event.target.name;
        let val = event.target.value;
        setproductData({
            ...productData,
            [name]: val
        })
    }

    const imgHandler = (e) => {
        console.log("-----------------------", e.target.files[0]);
        setproductData({
            ...productData, img: e.target.files[0]
        })
    }

    const handleSubmit = () => {
      
        axios.post(process.env.React_App_API_URL+`/product/updateProduct/${ID}`,
            {
                productName: productData.name,
                productType: productData.type,
                productImg: productData.img,
                prodDetails: productData.dets,
                productPrice:productData.price
            }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data '
            }
        })
            .then((response) => { 
                if (props.page == 0) {
                    props.getData(props.page + 1);
                }
                else{
                props.getData(props.page);
                }
                edittoggle();
                setID();
            })
            .catch(error => {
                console.log(error);
                if (props.page == 0) {
                    props.getData(props.page + 1);
                }
                else{
                props.getData(props.page);
                }
                let err = error.response.data.errors;
                console.log(err, "err");
                console.log(error);

                if (err) {
                    err.filter((val) => {
                        if (val.param === "productName") {
                                return setErrName(val.msg);
                        }
                        if (val.param === "productImg") {
                                return setErrImg(val.msg);
                            }
                        if (val.param === "prodDetails") {
                                return setErrDets(val.msg);
                            }
                        if (val.param === "productType") {
                                return setErrType(val.msg);
                        }
                        if (val.param === "productPrice") {
                            return setErrPrice(val.msg);
                    }
                    })
                }
            })
    }

    useEffect(() => {
        onEdit(props.id);
        // console.log("check props value",)s
    }, [props.id]);

    useEffect(() => {
        if (props.page == 0) {
            props.getData(props.page + 1);
        }
        else{
        props.getData(props.page);
        }
        console.log("useEffect",productData)
    }, []);

    useEffect(() => {
        const email = localStorage.getItem('email');
        if (email === null) {
            navigate('/admin/login');
        }
    }, [navigate])


    //jsx 
    return (

        <div>
            <Modal isOpen={editModal} toggle={edittoggle} >
                <ModalHeader toggle={edittoggle}>Edit Product Details!</ModalHeader>
                <ModalBody>
                <Form className="form">
              {/* <!-- Text Input--> */}
              <div className="row">
                {/* <!-- Area Chart --> */}
                <div className="col-4">
                  {/* <!-- Text Input--> */}
                  <div className="form-group">
                    <label className="control-label" for="product_name">PRODUCT NAME</label>
                  
                    <Input type="text" name="name" defaultValue={productData.name}  className="form-control Input-md" required="" placeholder="Enter Product Name" onChange={editHandler} />
                              {errName && <span style={{ color: "red" }}>{errName}</span>}

                  </div>
                </div>
                <div className="col-4">
                  {/* <!-- Select Basic --> */}
                  <div className="form-group">
                    <label className="control-label" for="product_categorie">PRODUCT TYPE</label>
                    <Input type="text" name="type" defaultValue={productData.type} placeholder="Enter Product Type" onChange={editHandler}  className="form-control Input-md" required="" />
                    {errType && <span style={{ color: "red" }}>{errType}</span>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  {/* <!-- Text Input--> */}
                  <div className="form-group">
                    <label className="control-label" for="available_quantity">PRODUCT DETAILS</label>
                    <Input   className="form-control Input-md" required="" type="text" name="dets" defaultValue={productData.dets} placeholder="Enter Product Details" onChange={editHandler} />
                    {errDets && <span style={{ color: "red" }}>{errDets}</span>}
                  </div>
                </div>
                <div className="col-6">
                  {/* <!-- Text Input--> */}
                  <div className="form-group">
                    <label className="control-label" for="product_weight">PRODUCT IMAGE</label>
                    <Input type="file" name="img"  className="form-control Input-md" required="" accept="image/png, image/jpg, image/jpeg" title="Choose a video please" onChange={(e) => imgHandler(e)} />
                            <img alt="no image" style={{ width: 60, height: 60 }} src={"http://peak-discount-api.imenso.in/ProductImg/" + productData.img} />
                          
                    {errImg && <span style={{ color: "red" }}>{errImg}</span>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label className="control-label" for="product_price">PRODUCT PRICE</label>
                    <Input type="number" name="price" defaultValue={productData.price}  className="form-control Input-md" required="" placeholder="Enter Product Price" onChange={editHandler} />
                              {errPrice && <span style={{ color: "red" }}>{errPrice}</span>}

                  </div>
                </div>
                </div>
            </Form>
                </ModalBody>
                <ModalFooter>
                <Button type="button"  onClick={edittoggle} className="btn btn-danger" data-dismiss="modal">Close</Button>
            <Button id="singlebutton" color="primary" name="singlebutton" className="btn btn-primary" onClick={handleSubmit}>submit</Button>
                </ModalFooter>
            </Modal>

        </div>

    );
}


export default EditProduct;



