import i1 from "./i1.jpg";
import i2 from "./i2.jpg";
import i3 from "./i3.jpg";
import i4 from "./i4.jpg";
import i5 from "./i5.jpg";
import i6 from "./i6.jpg";
import i7 from "./i7.jpg";
import i8 from "./i8.jpg";
import icon1 from "./features/f-icon1.png";
import icon2 from "./features/f-icon2.png";
import icon3 from "./features/f-icon3.png";
import icon4 from "./features/f-icon4.png";
import logo from "./logo.png";
import banner from "./banner/banner-bg.jpg";
import category from "./category/c5.jpg";
import r1 from "./r1.jpg";
import r2 from "./r2.jpg";
import r3 from "./r3.jpg";
import r5 from "./r5.jpg";
import r6 from "./r6.jpg";
import r7 from "./r7.jpg";
import r9 from "./r9.jpg";
import r10 from "./r10.jpg";
import r11 from "./r11.jpg";
import brand1 from "./brand/1.png";
import brand2 from "./brand/2.png";
import brand3 from "./brand/3.png";
import brand4 from "./brand/4.png";
import brand5 from "./brand/5.png";
import category1 from "./category/c1.jpg";
import category2 from "./category/c2.jpg";
import category3 from "./category/c3.jpg";
import category4 from "./category/c4.jpg";
import category5 from "./category/c5.jpg";
import p6 from "./product/p6.jpg";
import p8 from "./product/p8.jpg";
import p1 from "./product/p1.jpg";
import p3 from "./product/p3.jpg";
import p5 from "./product/p5.jpg";
import p4 from "./product/p4.jpg";
import ep1 from "./product/e-p1.png";

export const image = {
    i1,
    i2,
    i3,
    i4,
    i5,
    i6,
    i7,
    i8,
    r1,
    r2,
    r3,
    r5,
    r6,
    r7,
    r9,
    r10,
    r11,
    icon1,
    icon2,
    icon3,
    icon4,
    logo,
    banner,
    category,
    brand1,
    brand2,
    brand3,
    brand4,
    brand5,
    category1,
    category2,
    category3,
    category4,
    category5,
    p6,
    p8,
    p1,
    p3,
    p5,
    p4,
    ep1,

}