import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Button, Input } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import login from '../assets/img/login.jpg';
import { image } from "../assets/img/exportImage"
import ForgetPswd from './forgetPswd';

const UserLogin = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState({ email: "", password: "" });
    const [emailErr, setEmailErr] = useState("");
    const [passwordErr, setPasswordErr] = useState("");
    const [Msg, setMsg] = useState("");
    const [searchField, setSearchField] = useState(false);

    const openSearch = () => {
        setSearchField(!searchField);
        }
   

    const postData = () => {
        axios.post('http://peak-discount-api.imenso.in/users/logIn',
            {
                email: values.email,
                password: values.password
            }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((response) => {
                console.log("res", response);
                localStorage.setItem('user_email', response.data.existingUser.email);
                localStorage.setItem('userID', response.data.existingUser._id);
                navigate('/user/dashboard');
            })
            .catch(error => {
                console.log(error);
                let err = error.response.data.errors;
                            let message = error.response.data.message;
                            setMsg(message);
                            console.log("message", message);
                            console.log(err);

                            err.filter((val) => {
                                if (val.param === "email") {
                                    setEmailErr(err[0].msg);
                                }
                                else if (val.param === "password") {
                                    setPasswordErr(val.msg);
                                }
                            })
            })
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            postData();
        }
    }

    const handleChange = (event) => {
        let name = event.target.name;
        let val = event.target.value;
        setValues({
            ...values,
            [name]: val,
        })
    }

    const ifLoggedIn = () => {
        const email = localStorage.getItem('user_email');
        if (email) {
            navigate('/user/dashboard');
        }
    }

    useEffect(() => {
        ifLoggedIn();
    }, []);


    useEffect(() => {
    }, [values]);

    // useEffect(() => {
    //     const email = localStorage.getItem('email');
    //     if (email) {
    //         navigate('/admin/dashboard');
    //     }
    // }, []);


    const handleSubmit = () => {
        postData();
    }


    return (

        <div >
            <header className="header_area sticky-header">
                <div className="main_menu">
                    <nav className="navbar navbar-expand-lg navbar-light main_box">
                        <div className="container">
                            {/* <!-- Brand and toggle get grouped for better mobile display --> */}
                            <Link to="/user/dashboard" className="navbar-brand logo_h"><img src="img/logo.png" alt="" /></Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
                            <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                                <ul className="nav navbar-nav menu_nav ml-auto">
                                    <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
                                    <li className="nav-item"><Link to="/admin/login" className="nav-link">Admin Panel</Link></li>

                                    <li className="nav-item submenu dropdown">
                                        <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false">Shop</Link>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><Link to="" className="nav-link" >Shop Category</Link></li>
                                            <li className="nav-item"><Link to="" className="nav-link">Product Details</Link></li>
                                            <li className="nav-item"><Link to="" className="nav-link">Product Checkout</Link></li>
                                            <li className="nav-item"><Link to="" className="nav-link">Shopping Cart</Link></li>
                                            <li className="nav-item"><Link to="" className="nav-link" >Confirmation</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item submenu dropdown active">
                                        <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false">Pages</Link>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item active"><Link to="/" className="nav-link" >Login</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><Link to="" className="nav-link" >Contact</Link></li>
                                </ul>
                                <ul className="nav navbar-nav navbar-right">
                                    <li className="nav-item"><Link to="#" className="cart" title='Add To Cart'><span className="ti-bag"></span></Link></li>
                                    <li className="nav-item">
                                        <button className="search" onClick={openSearch}><span className="lnr lnr-magnifier" id="search"></span></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                {searchField== true?
               (<div className="search_input" id="search_input_box">
              <div className="container">
                  <form className="d-flex justify-content-between">
                      <input type="text" className="form-control" id="search_input" placeholder="Search Here" />
                      <button type="submit" onClick={openSearch} className="btn"> <span className="lnr lnr-cross" id="close_search" title="Close Search"></span></button>
                     
                  </form>
              </div>
          </div>):""}

                {/* <div className="search_input" id="search_input_box">
                    <div className="container">
                        <form className="d-flex justify-content-between">
                            <input type="text" className="form-control" id="search_input" placeholder="Search Here" />
                            <button type="submit" className="btn"></button>
                            <span className="lnr lnr-cross" id="close_search" title="Close Search"></span>
                        </form>
                    </div>
                </div> */}
            </header>
            {/* <!-- End Header Area -->
    
	<!-- Start Banner Area --> */}
            <section className="banner-area organic-breadcrumb">
                <div className="container">
                    <div className="breadcrumb-banner d-flex flex-wrap align-items-center justify-content-end">
                        <div className="col-first">
                            <h1>Login/Register</h1>
                            <nav className="d-flex align-items-center">
                                <Link >Home<span className="lnr lnr-arrow-right"></span></Link>
                                <Link >Login/Register</Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Banner Area --> */}


            {/* <!--================Login Box Area =================--> */}
            <section className="login_box_area section_gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="login_box_img">
                                <img className="img-fluid" src={login} alt="" />
                                <div className="hover">
                                    <h4>New to our website?</h4>
                                    <p>There are advances being made in science and technology everyday, and a good example of this is the</p>
                                    <Link to="/user/signup" className="primary-btn" >Create an Account</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login_form_inner">
                                <h3>Log in User Panel</h3>
                                <form className="row login_form"id="contactForm" noValidate="noValidate">
                                    <div className="col-md-12 form-group">

                                        <input type="email" onKeyDown={handleKeyDown} name="email" className="form-control" id="name" onChange={handleChange} placeholder="Username" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Username'" />
                                        {emailErr && <span style={{ color: "red" }}>{emailErr}</span>} 
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <input type="password" onKeyDown={handleKeyDown} name="password" onChange={handleChange} className="form-control" id="name" placeholder="Password" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Password'" />
                                        {passwordErr && <span style={{ color: "red" }}>{passwordErr}</span>}
                                    </div>
                                    <p style={{ color: "red" }}>{Msg}</p>
                                    <div className="col-md-12 form-group">
                                    </div>
                                    {/* <div className="col-md-12 form-group">
								<Link to="/forget">Forgot Password?</Link>
							</div> */}
                            <ForgetPswd/>
                                </form>
                                <Button type="submit" className="primary-btn" value="Submit" color='warning'
                                    onClick={handleSubmit}
                                >
                                    Login
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================End Login Box Area =================--> */}
            {/* <Form>

                <FormGroup>
                    <Label for="email">
                        <strong>Email: </strong> </Label>
                    <Input type="email" name="email" placeholder="E-mail" onChange={handleChange}
                    // onKeyDown={handleKeyDown} 
                    /> */}
            {/* {emailErr && <span>{emailErr}</span>} */}
            {/* </FormGroup>
                <FormGroup>
                    <Label for="password">
                        <strong>Password: </strong> </Label>
                    <Input type="password" name="password" placeholder="password" onChange={handleChange}
                    // onKeyDown={handleKeyDown} 
                    />
                    {passwordErr && <span>{passwordErr}</span>}
                </FormGroup>

            </Form> */}
            {/* <Button type="submit" className="btn btn-primary btn-user btn-block" value="Submit" color='primary'
                onClick={handleSubmit}
            >
                Login
            </Button> */}
            <br />
            <div className="text-center">
                {/* <p style={{ color: "red" }}>{Msg}</p> */}
            </div>
            {/* <!-- start footer Area --> */}
            <footer className="footer-area section_gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3  col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h6>About Us</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore dolore
                                    magna aliqua.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4  col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h6>Newsletter</h6>
                                <p>Stay update with our latest</p>
                                <div className="" id="mc_embed_signup">

                                    <form target="_blank" noValidate={true} action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                                        method="get" className="form-inline">

                                        <div className="d-flex flex-row">

                                            <input className="form-control" name="EMAIL" placeholder="Enter Email" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter Email '"
                                                required="" type="email" />


                                            <button className="click-btn btn btn-default"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                                            <div style={{ position: "absolute", left: -5000 }}>
                                                <input name="b_36c4fd991d266f23781ded980_aefe40901a" tabIndex="-1"  type="text" />
                                            </div>

                                            {/* <!-- <div className="col-lg-4 col-md-4">
													<button className="bb-btn btn"><span className="lnr lnr-arrow-right"></span></button>
												</div>  --> */}
                                        </div>
                                        <div className="info"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3  col-md-6 col-sm-6">
                            <div className="single-footer-widget mail-chimp">
                                <h6 className="mb-20">Instragram Feed</h6>
                                <ul className="instafeed d-flex flex-wrap">
                                    <li><img src={image.i1} alt="" /></li>
                                    <li><img src={image.i2} alt="" /></li>
                                    <li><img src={image.i3} alt="" /></li>
                                    <li><img src={image.i4} alt="" /></li>
                                    <li><img src={image.i5} alt="" /></li>
                                    <li><img src={image.i6} alt="" /></li>
                                    <li><img src={image.i7} alt="" /></li>
                                    <li><img src={image.i8} alt="" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h6>Follow Us</h6>
                                <p>Let us be social</p>
                                <div className="footer-social d-flex align-items-center">
                                    <Link to="#"><i className="fa fa-facebook"></i></Link>
                                    <Link to="#"><i className="fa fa-twitter"></i></Link>
                                    <Link to="#"><i className="fa fa-dribbble"></i></Link>
                                    <Link to="#"><i className="fa fa-behance"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom d-flex justify-content-center align-items-center flex-wrap">
                        <p className="footer-text m-0">
                            Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart-o" aria-hidden="true"></i> by <Link to="https://colorlib.com" target="_blank">Colorlib</Link>
                            {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                        </p>
                    </div>
                </div>
            </footer>
            {/* <!-- End footer Area --> */}

        </div>

    );
}

export default UserLogin;







