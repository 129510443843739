import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import { Table, Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import EditProduct from "./edit";
import CreateProduct from "./create";
import ReactPaginate from 'react-paginate';
import LogOut from "../logout";


const AllProduct = () => {
  const [searchText, setsearchText] = useState("");
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [editId, setEditId] = useState(0);
  const [displayList, setdisplayList] = useState([]);
  const [msg, setMsg] = useState("");
  const [totalPages, setPages] = useState(0);
  const [sendPage, setSendPage] = useState(0);
  const [data, setdata] = useState({ name: "", img: [], dets: "", type: "", price: 0 });
  const [errType, setErrType] = useState("");
  const [errImg, setErrImg] = useState("");
  const [errDets, setErrDets] = useState("");
  const [errPrice, setErrPrice] = useState("");
  const [errName, setErrName] = useState("");
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const createProductUrl = process.env.React_App_API_URL + '/product/createProduct';
  const [pasted, setPasted] = React.useState(false);

  //functions

  const toggle = () => {
    setModal(!modal)
    if (modal === false) {
      setErrDets("");
      setErrImg("");
      setErrName("");
      setErrType("");
      setMessage("");
      setErrPrice("");
    }
  };

  const getData = (currentPage) => {
    axios.get(process.env.React_App_API_URL + `/product/getAllProduct?page=${currentPage}&search=${searchText}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }).then(function (response) {
      let data = response.data.data;
      // console.log("222", data);
      setdisplayList(data);
      setPages(response.data.totalPages);
    })
  }


  const onDelete = (id) => {
    let url = process.env.React_App_API_URL + `/product/deleteProduct/${id}`;
    axios.post(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        console.log(sendPage, "pageno");
        if(sendPage== 0)
        {
            getData(sendPage +1);
        }
        else{getData(sendPage);
        }
      })
      .catch(function (error) {
        console.log(error);
        console.log(sendPage, "pageno");
        if(sendPage== 0)
        {
            getData(sendPage +1);
        }
        else{getData(sendPage);
        }
      });
  }


  const handleEdit = (id) => {
    localStorage.setItem('prod_id', id);
    setEditId(id);
  }

  const handleDelete = (id) => {
    onDelete(id);
  }

  useEffect(() => {
    console.log("editId", editId);
  }, [editId])

  const isLoggedIn = () => {
    const email = localStorage.getItem('email');
    if (email === null) {
      navigate('/admin/login');
    }
  }

  useEffect(() => {
    isLoggedIn();
    getData();
  }, [])


  const searchHandler = (e) => {
    console.log(e.target.value, "test");
    var text = e.target.value;
    if (!pasted) {
      setsearchText(text);
    }
    setPasted(false);
    if (text == "") {
      navigate("/admin/product");
    }
    else {
      setsearchText(text);
    }
    // getData();

  }

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;
    getData(currentPage);
    setSendPage(currentPage);
  }

  const handleChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setdata({
      ...data,
      [name]: val,
    })
  }

  useEffect(() => {
  }, [data]);

  useEffect(() => {
    getData();
  }, [searchText]);

  const handleImg = (e) => {
    console.log("target.files", e.target.files[0]);
    setdata({
      ...data, img: e.target.files[0]
    })
  }

  //to create product
  const postData = () => {
    console.log("img", data.img);
    axios.post(createProductUrl,
      {
        prodDetails: data.dets,
        productName: data.name,
        productType: data.type,
        productImg: data.img,
        productPrice: data.price
      }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data '
      }
    })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200)
          setdata({ name: "", img: [], dets: "", type: "", price: 0 });
        getData();
      })
      .catch(error => {
        console.log(error);
        let err = error.response.data.errors;
        console.log(err, "err");
        err.filter((val) => {
          if (val.param === "productName") {
            setErrName(val.msg);
          }
          if (val.param == "productImg") {
            setErrImg(val.msg);
          }
          if (val.param === "prodDetails") {
            setErrDets(val.msg);
          }
          if (val.param === "productType") {
            setErrType(val.msg);
          }
          if (val.param === "productPrice") {
            setErrPrice(val.msg);
          }
        })
      })
  }

  const handlePaste = () => {
    setPasted(true);
  };

  // jsx
  return (<div>

    <div id="wrapper">
      {/* <!-- Sidebar --> */}
      <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        {/* <!-- Sidebar - Brand --> */}
        <Link to="/admin/dashboard" className="sidebar-brand d-flex align-items-center justify-content-center" >
          <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-laugh-wink"></i>
          </div>
          <div className="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
        </Link>
        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />
        {/* <!-- Nav Item - Dashboard --> */}
        <li className="nav-item active">
          <Link to="/admin/dashboard" className="nav-link" >
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span></Link>
        </li>
        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />
        {/* <!-- Heading --> */}
        <div className="sidebar-heading"> Products Page </div>
        <li className="nav-item">
            <Link to="/admin/product" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Products Page</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/bundle" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Bundle Page</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/campaign" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Campaign Page</span>
            </Link>
          </li>
        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />
      </ul>
      {/* <!-- End of Sidebar --> */}
      {/* <!-- Content Wrapper --> */}

      <div id="content-wrapper" className="d-flex flex-column">
        {/* <!-- Main Content --> */}
        <div id="content">
          {/* <!-- Topbar --> */}
          <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            {/* <!-- Sidebar Toggle (Topbar) --> */}
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
              <i className="fa fa-bars"></i>
            </button>
            {/* <!-- Topbar Search --> */}

            <Form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
              <div className="Input-group">
                <Input type="text"
                  className="form-control bg-light border-0 small"
                  aria-label="Search" aria-describedby="basic-addon2"
                  id="outlined-basic"
                  onChange={searchHandler}
                  onKeyPress={getData}
                  // onPaste={handlePaste}
                  onPasteCapture={handlePaste}
                  variant="outlined"
                  placeholder="search for..."
                  label="Search"
                />
                <div Name="input-group-append">
                  <Button color="primary" className="btn btn-primary" type="button" onClick={getData}> <i className="fas fa-search fa-sm"></i>
                  </Button>
                </div>
              </div>
            </Form>
            <LogOut />
            {/* <!-- Topbar Navbar --> */}
            <ul className="navbar-nav ml-auto">
              <div className="topbar-divider d-none d-sm-block"></div>
              {/* <!-- Nav Item - User Information --> */}
              <li className="nav-item dropdown no-arrow">
                <Link to="#" className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="mr-2 d-none d-lg-inline text-gray-600 small">Douglas McGee</span>
                  <img className="img-profile rounded-circle" src="../img/undraw_profile.svg" />
                </Link>
                {/* <!-- Dropdown - User Information --> */}
                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                  <Link to="#" className="dropdown-item" >
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                  </Link>
                  <Link to="#" className="dropdown-item" >
                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                    Settings
                  </Link>
                  <Link to="#" className="dropdown-item" >
                    <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                    Activity Log
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link to="#" className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                  </Link>
                </div>
              </li>
            </ul>
          </nav>
          {/* <!-- End of Topbar --> */}
          {/* <!-- Begin Page Content --> */}
          <div className="container-fluid">
            {/* <!-- Page Heading --> */}
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              {/* <h1 className="h3 mb-0 text-gray-800">Dashboard</h1> */}
              <div>
                {/* <!-- Button to Open the Modal --> */}
                {/* <button type="button" className="btn btn-sm btn-primary shadow-sm" data-toggle="modal" data-target="#addProduct"><i className="fas fa-plus fa-sm text-white-50"></i> Add Product</button> */}
                <CreateProduct getData={getData} /> </div>
            </div>
            {/* <!-- Content Row --> */}

            {/* <CreateProduct getData={getData} /> */}

            <EditProduct handleEdit={handleEdit} id={editId} getData={getData} page={sendPage} />

            {/* <!-- Content Row --> */}
            <div className="row">
              {/* <!-- Area Chart --> */}
              <div className="col-xl-12">
                <div className="card shadow mb-4">
                  {/* <!-- Card Header - Dropdown --> */}
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Product list</h6>
                  </div>
                  {/* <!-- Card Body --> */}
                  <div className="card-body">


                    <Table striped hover>
                      <thead>
                        <tr>
                          <th scope="col">Product Name</th>
                          <th scope="col"></th>
                          <th scope="col">Product Price</th>
                          <th scope="col">Product Type</th>
                          <th scope="col">Product Details</th>
                          {/* <th scope="col">Edit Product </th>
                          <th scope="col">Delete Product </th> */}
                          <th scope="col" width="100" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>


                        {displayList.length > 0 ?
                          displayList.map((data, index) =>

                            <tr key={index}>
                              <td>{data.productName}</td>
                              <th scope="row"><img class="" src={"http://peak-discount-api.imenso.in/ProductImg/" + data.productImg} alt="image not available" width="60" /></th>
                              <td>{data.productPrice}</td>
                              <td>{data.productType}</td>
                              <td>{data.prodDetails}</td>
                              {/* <td><Button color="primary" onClick={() => { handleEdit(data._id) }}> Edit</Button></td>
                              <td><Button color="primary" onClick={() => { handleDelete(data._id) }}> Delete</Button></td> */}
                              <th scope="col" class="text-center">
                                <button class="btn btn-default btn-sm" onClick={() => { handleEdit(data._id) }}><i class="fas fa-pencil-alt"></i></button>
                                <button class="btn btn-default btn-sm" onClick={() => { handleDelete(data._id) }}><i class="far fa-trash-alt"></i></button>
                              </th>
                            </tr>

                          )
                          :
                          <tr>
                           <td colSpan={6} align="center"> <h3>No Record Found</h3></td>
                          </tr>

                        }
                      </tbody>


                    </Table>
             <div>
            
                    <ReactPaginate
                      previousLabel={"← Previous"}
                      nextLabel={"Next →"}
                      pageCount={totalPages}
                      breakLabel="..."
                      pageRangedisplayListed={2}
                      renderOnZeroPageCount={null}
                      breakClassName={"page-link"}
                      breakLinkClassName={"pagination-link"}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center text-uppercase text-nowrap "}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-link"}
                      previousLinkClassName={"pagination__link"}
                      nextClassName={"page-link"}
                      nextLinkClassName={"pagination__link"}
                      subContainerClassName={'pages pagination'}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"active"}
                    />
</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /.container-fluid --> */}
        </div>
        {/* <!-- End of Main Content --> */}
        {/* <!-- Footer --> */}
        <footer className="sticky-footer bg-white">
          <div className="container my-auto">
            <div className="copyright text-center my-auto">
              <span>Copyright © Your Website 2021</span>
            </div>
          </div>
        </footer>
        {/* <!-- End of Footer --> */}
      </div>
      {/* <!-- End of Content Wrapper --> */}
    </div>

    {/*<!-- The Modal -->*/}
    <div className="modal fade" id="addProduct">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          {/* <!-- Modal Header --> */}
          <div className="modal-header">
            <h4 className="modal-title">Add a New Product!</h4>
            <button type="button" className="close" data-dismiss="modal">&times;</button>
          </div>

          {/* <!-- Modal body --> */}
          <div>
            {/* < Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Add a New Product!</ModalHeader>
          <ModalBody> */}

            <Form className="form">
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label className="control-label" for="product_name">PRODUCT NAME</label>
                    <Input id="product_name" name="name" placeholder="PRODUCT NAME" className="form-control Input-md" required="" type="text" onChange={handleChange} />
                    {errName && <span style={{ color: "red" }}>{errName}</span>}

                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="control-label" for="product_categorie">PRODUCT TYPE</label>
                    <Input id="product_type" name="type" type="text" placeholder="PRODUCT TYPE" onChange={handleChange} className="form-control Input-md" required="" />
                    {errType && <span style={{ color: "red" }}>{errType}</span>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="control-label" for="available_quantity">PRODUCT DETAILS</label>
                    <Input id="product_details" name="dets" placeholder="PRODUCT DETAILS" className="form-control Input-md" required="" type="text" onChange={handleChange} />
                    {errDets && <span style={{ color: "red" }}>{errDets}</span>}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="control-label" for="product_weight">PRODUCT IMAGE</label>
                    <Input id="product_image" placeholder="PRODUCT IMAGE" className="form-control Input-md" required="" type="file" accept="image/png, image/jpg, image/jpeg" name="img" onChange={(e) => handleImg(e)} />
                    {errImg && <span style={{ color: "red" }}>{errImg}</span>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label className="control-label" for="product_price">PRODUCT PRICE</label>
                    <Input id="product_price" name="price" placeholder="PRODUCT PRICE" className="form-control Input-md" required="" type="number" onChange={handleChange} />
                    {errPrice && <span style={{ color: "red" }}>{errPrice}</span>}

                  </div>
                </div>
              </div>
              <p style={{ color: "red" }}>{message}</p>
            </Form>
          </div>
          {/* </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal> */}

          {/* <!-- Modal footer --> */}
          <div className="modal-footer">
            <Button type="button" className="btn btn-danger" data-dismiss="modal">Close</Button>
            <Button id="singlebutton" color="primary" name="singlebutton" className="btn btn-primary" onClick={postData}>submit</Button>
          </div>
        </div>
      </div>
    </div>

  </div>);

}
export default AllProduct;