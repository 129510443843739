
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import LogOut from "./logout";

const AdminPanel = () => {
  const [searchText, setsearchText] = useState("");
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [editId, setEditId] = useState(0);
  const [displayList, setdisplayList] = useState([]);
  const [msg, setMsg] = useState("");
  const [totalPages, setPages] = useState(0);
  const [sendPage, setSendPage] = useState(0);

  //functions
  const onDelete = (id) => {
    let url = process.env.React_App_API_URL + `/product/deleteProduct/${id}`;
    axios.post(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        getData(sendPage);
      })
      .catch(function (error) {
        console.log(error);
        getData();
      });
  }

  const getData = (currentPage) => {
    axios.get(process.env.React_App_API_URL + `/product/getAllProduct?page=${currentPage}&search=${searchText}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
    }).then(function (response) {
      let data = response.data.data;
      console.log("dataa", data);
      setdisplayList(data);
      setPages(response.data.totalPages);
    })
  }

  const handleEdit = (id) => {
    localStorage.setItem('prod_id', id);
    setEditId(id);
  }

  const handleDelete = (id) => {
    onDelete(id);
  }

  useEffect(() => {
    console.log("editId", editId);
  }, [editId])

  const isLoggedIn = () => {
    const email = localStorage.getItem('email');
    if (email === null) {
      navigate('/admin/login');
    }
  }

  useEffect(() => {
    isLoggedIn();
    getData();
  }, [])


  const searchHandler = (e) => {
    var text = e.target.value;
    if (text == "") {
      navigate("/admin/product");
    }
    else {
      setsearchText(text);
    }
  }

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;
    getData(currentPage);
    setSendPage(currentPage);
  }


  return (
    <>
      {/* body */}

      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
          {/* <!-- Sidebar - Brand --> */}
          <Link to="/admin/dashboard" className="sidebar-brand d-flex align-items-center justify-content-center" >
            <div className="sidebar-brand-icon rotate-n-15">
              <i className="fas fa-laugh-wink"></i>
            </div>
            <div className="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
          </Link>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider my-0" />
          {/* <!-- Nav Item - Dashboard --> */}
          <li className="nav-item active">
            <Link to="/admin/dashboard" className="nav-link" >
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span></Link>
          </li>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider" />
          {/* <!-- Heading --> */}
          <div className="sidebar-heading"> Pages </div>
          <li className="nav-item">
            <Link to="/admin/product" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Products Page</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/bundle" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Bundle Page</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/campaign" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Campaign Page</span>
            </Link>
          </li>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider" />
        </ul>
        {/* <!-- End of Sidebar --> */}
        {/* <!-- Content Wrapper --> */}

        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              {/* <!-- Sidebar Toggle (Topbar) --> */}
              <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars"></i>
              </button>
             
              <LogOut />
              {/* <!-- Topbar Navbar --> */}
              <ul className="navbar-nav ml-auto">
                <div className="topbar-divider d-none d-sm-block"></div>
                {/* <!-- Nav Item - User Information --> */}
                <li className="nav-item dropdown no-arrow">
                  <Link to="#" className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">Douglas McGee</span>
                    <img className="img-profile rounded-circle" src="../img/undraw_profile.svg" />
                  </Link>
                  {/* <!-- Dropdown - User Information --> */}
                  <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                    <Link to="#" className="dropdown-item" >
                      <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      Profile
                    </Link>
                    <Link to="#" className="dropdown-item" >
                      <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                      Settings
                    </Link>
                    <Link to="#" className="dropdown-item" >
                      <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                      Activity Log
                    </Link>
                    <div className="dropdown-divider"></div>
                    {/* <Link to="/logout" className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </Link> */}
                     <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    <LogOut/>
                  </div>
                </li>
              </ul>
            </nav>
            {/* <!-- End of Topbar --> */}
            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                <div>
                  {/* <!-- Button to Open the Modal --> */}
                  {/* <button type="button" className="btn btn-sm btn-primary shadow-sm" data-toggle="modal" data-target="#addProduct"><i className="fas fa-plus fa-sm text-white-50"></i> Add Product</button> */}
                  {/* <!-- <Link to="add-produc.html" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-plus fa-sm text-white-50"></i> Add Product</Link> --> */}
                </div>
              </div>
              {/* <!-- Content Row --> */}

              <div className="row">
                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            Earnings (Monthly)
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">$40,000</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-calendar fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-success shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                            Earnings (Annual)
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">$215,000</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-info shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
                          </div>
                          <div className="row no-gutters align-items-center">
                            <div className="col-auto">
                              <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
                            </div>
                            <div className="col">
                              <div className="progress progress-sm mr-2">
                                <div className="progress-bar bg-info" role="progressbar" style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Pending Requests Card Example --> */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                            Pending Requests
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-comments fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <CreateProduct getData={getData} /> */}

              {/* <EditProduct handleEdit={handleEdit} id={editId} getData={getData} page={sendPage} /> */}

              {/* <!-- Content Row --> */}
              <div className="row">
                {/* <!-- Area Chart --> */}
                <div className="col-xl-12">
                  <div className="card shadow mb-4">
                   
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          {/* <!-- Footer --> */}
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright © Your Website 2021</span>
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>

    
    </>
  );
}
export default AdminPanel;