import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import DatePicker from "react-date-picker";
import { getDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import dateFormat, { masks } from "dateformat";

const CreateCampaign = (props) => {
  // useState
  const [startDate, setStartDate] = useState(new Date());
  const [campaignArray, setcampaignArray] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [selectBundle, setSelectBundle] = useState(campaignArray[0]?._id);
  const token = localStorage.getItem("token");
  const [data, setdata] = useState({
    start: startDate,
    end: endDate,
    bundle: selectBundle,
    price: 0,
    sale: "",
    endPrice: 0,
    status: "activate",
  });
  const navigate = useNavigate();
  const campaignUrl =
    process.env.React_App_API_URL + "/campaign/createCampaign";
  const bundleUrl =
    process.env.React_App_API_URL + "/bundle/getAllBundle?limit=1000";
  const [modal, setModal] = useState(false);
  const [errStart, setErrStart] = useState("");
  const [errSale, setErrSale] = useState("");
  const [errEnd, setErrEnd] = useState("");
  const [errPrice, setErrPrice] = useState("");
  const [errRange, setErrRange] = useState("");
  const [errBundle, setErrBundle] = useState("");
  const [errStatus, setErrStatus] = useState("");
  const [message, setMessage] = useState("");
  getDefaultLocale(es);

  //functions

  const toggle = () => {
    setModal(!modal);
    getBundle();
    if (modal === false) {
      setErrStart("");
      setErrSale("");
      setErrEnd("");
      setErrRange("");
      setErrPrice("");
      setErrBundle("");
      setErrStatus("");
      setMessage("");
    }
  };

  const handleChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    data.bundle = selectBundle;
    data.start = startDate;
    data.end = endDate;

    setdata({
      ...data,
      bundle: selectBundle,
      start: startDate,
      end: endDate,
    });
    setdata({
      ...data,
      [name]: val,
    });
  };

  const getBundle = () => {
    axios
      .get(bundleUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        let data = response.data.data;
        if (data) {
          const newArray = data.map((item) => {
            return { value: item._id, label: item.bundleName };
          });
          setcampaignArray(newArray);
          setSelectBundle(newArray[0]?.value);
        }
      });
  };

  const handleSubmit = () => {
    postData();
    // data.reset()
  };

  // to create campaign
  const postData = () => {
   
    const start = dateFormat(startDate, "isoDateTime");
    const splitStartDate = start.split("T")[0];

    const end = dateFormat(endDate, "isoDateTime");
    const splitEndDate = end.split("T")[0];

    let params = {
      startingPrice: parseInt(data.price),
      // startingPrice: data.price,
      bundle: selectBundle,
      startDate: splitStartDate,
      endDate: splitEndDate,
      salesTarget: data.sale,
      endPrice: parseInt(data.endPrice),
      status: data.status?data.status:"activate",
    };
    console.log("para", params);
    axios.post(campaignUrl, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(555,response.data);
        if (response?.status === 200) {
          setdata({
            start: startDate,
            end: endDate,
            bundle: selectBundle,
            price: 0,
            sale: "",
            endPrice: 0,
            status: "",
          });
          setStartDate(new Date());
          setEndDate(new Date());
          props.getData();
        toggle();
        }
        
      })
      .catch((error) => {
        console.log(error);
        let err = error?.response?.data?.errors;
        console.log(err, "err");
        let message = error?.response?.data?.message;
        setMessage(message);
        // setErrMsg(msg);
        if (err) {
          err.filter((val) => {
            if (val.param == "startDate") {
              setErrStart(val.msg);
            }
            if (val.param == "endDate") {
              setErrEnd(val.msg);
            }
            if (val.param === "endPrice") {
              setErrRange(val.msg);
            }
            if (val.param === "salesTarget") {
              setErrSale(val.msg);
            }
            if (val.param === "bundle") {
              setErrBundle(val.msg);
            }
            if (val.param === "startingPrice") {
              setErrPrice(val.msg);
            }

            return true;
          });
        }
      });
  };

  const isLoggedin = () => {
    const email = localStorage.getItem("email");
    if (email === null) {
      navigate("/admin/login");
    }
  };

  useEffect(() => {
    isLoggedin();
  }, []);

  useEffect(() => {
    props.getData();
  }, [data]);

  return (
    <div>
      <Button
        color="primary"
        className="btn btn-sm btn-primary shadow-sm"
      
        onClick={toggle}
      >
        <i className="fas fa-plus fa-sm text-white-50"></i> Add Campaign
      </Button>

      <FormGroup className="d-inline-block ml-2">
        <Input
          id="exampleDate"
          name="date"
          placeholder="date placeholder"
          type="date"
          onChange={(e) => props.setSelectedDate(e.target.value)}
        />
      </FormGroup>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Add a New Campaign!</ModalHeader>
        <ModalBody>
          <Form className="form">
            {/* <!-- Text Input--> */}
            <div className="row">
              {/* <!-- Area Chart --> */}
              <div className="col-4">
                <div className="form-group">
                  <label className="control-label" for="start_date">
                    START DATE
                  </label>
                  <DatePicker
                    locale={es}
                    minDate={new Date()}
                    value={startDate}
                    dateFormat="yyyy/MM/dd"
                    required=""
                    onChange={(date) => setStartDate(date)}
                  />
                  {errStart && <span style={{ color: "red" }}>{errStart}</span>}
                </div>
              </div>
              <div className="col-4">
                {/* <!-- Text Input--> */}
                <div className="form-group">
                  <label className="control-label" for="end">
                    END DATE{" "}
                  </label>
                  <DatePicker
                    value={endDate}
                    minDate={new Date()}
                    dateFormat="yyyy/MM/dd"
                    onChange={(date) => setEndDate(date)}
                  />
                  {errEnd && (
                    <span style={{ color: "red" }}>{errEnd}Invalid</span>
                  )}
                </div>
              </div>
              <div className="col-4">
                {/* <!-- Select Basic --> */}
                <div className="form-group">
                  <label className="control-label" for="bundle">
                    BUNDLE
                  </label>
                  {/* <Select
                    options={campaignArray}
                    onChange={(e) => setSelectBundle(e.value)}

                  ></Select> */}
                  <select onChange={(e) => setSelectBundle(e.target.value)} style={{width:"150px",height:"30px"}}>
                  {campaignArray?.map((item)=>{
                    return <option value={item.value}>{item.label}</option>
                  })} 
                  </select>
                  {errBundle && (
                    <span style={{ color: "red" }}>{errBundle}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                {/* <!-- Text Input--> */}
                <div className="form-group">
                  <label className="control-label" for="price">
                    STARTING PRICE
                  </label>
                  <Input
                    id="starting_price"
                    name="price"
                    placeholder="STARTING PRICE"
                    className="form-control Input-md"
                    onChange={handleChange}
                    required=""
                    type="number"
                  />
                  {errPrice && <span style={{ color: "red" }}>{errPrice}</span>}
                </div>
              </div>
              <div className="col-6">
                {/* <!-- Text Input--> */}
                <div className="form-group">
                  <label className="control-label" for="sale">
                    SALE TARGET
                  </label>
                  <Input
                    id="sale_target"
                    name="sale"
                    placeholder="SALE TARGET"
                    className="form-control Input-md"
                    onChange={handleChange}
                    required=""
                    type="text"
                  />
                  {errSale && <span style={{ color: "red" }}>{errSale}</span>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                {/* <!-- Text Input--> */}
                <div className="form-group">
                  <label className="control-label" for="product_description">
                    END PRICE
                  </label>

                  <Input
                    id="price_range"
                    name="endPrice"
                    type="number"
                    placeholder="END PRICE"
                    className="form-control Input-md"
                    onChange={handleChange}
                    required=""
                  />
                  {errRange && <span style={{ color: "red" }}>{errRange}</span>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                {/* <!-- Text Input--> */}
                <div className="form-group">
                  <label className="control-label" for="approuved_by">
                    STATUS
                  </label>&nbsp;&nbsp;
                <select  onChange={handleChange} name="status">
										<option value="activate" Checked>Activate</option>
										<option value="deactivate">Deactivate</option>
										
								   </select>
                  {errStatus && (
                    <span style={{ color: "red" }}>{errStatus}</span>
                  )}
                </div>
              </div>
            </div>
            <p style={{ color: "red" }}>{message}</p>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="Button"
            className="btn btn-danger"
            onClick={toggle}
            data-dismiss="modal"
          >
            Close
          </Button>
          <Button
            id="singleButton"
            name="singleButton"
            color="primary"
            onClick={handleSubmit}
            className="btn btn-primary"
          >
            submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default CreateCampaign;
