import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  Form,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
} from "reactstrap";
import DatePicker from "react-date-picker";
import { getDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import dateFormat, { masks } from "dateformat";

const EditCampaign = (props) => {
  //useState
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectBundle, setSelectBundle] = useState([
    { label: "select", value: "" },
  ]);
  const [campaignData, setcampaignData] = useState({
    start: dateFormat(startDate, "isoDateTime"),
    end: dateFormat(endDate, "isoDateTime"),
    bundle: selectBundle,
    price: 0,
    sale: "",
    endPrice: 0,
    status: "",
  });
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [editModal, setEditModal] = useState(false);
  const bundleArray = [];
  const [errStart, setErrStart] = useState("");
  const [errSale, setErrSale] = useState("");
  const [errEnd, setErrEnd] = useState("");
  const [errPrice, setErrPrice] = useState("");
  const [errRange, setErrRange] = useState("");
  const [errBundle, setErrBundle] = useState("");
  const [errStatus, setErrStatus] = useState("");
  const [Bundle, setBundle] = useState([]);
  const ID = localStorage.getItem("camp_id");
  const date = dateFormat(new Date(), "isoDateTime");
  const splitNewDate = date.split("T")[0];
  const splitDate = splitNewDate.split("-").join("/");
  // const [campaignDate, setCampaignDate] = useState(new Date(splitDate));
  const [campaignDate, setCampaignDate] = useState("");
  getDefaultLocale(es);
  const campaignUrl =
    process.env.React_App_API_URL + `/campaign/getCampaign?limit=500`;
  const [message, setMessage] = useState("");

  //functions
  function edittoggle() {
    setEditModal(!editModal);
    if (editModal === true) {
      props.handleEdit();
    }
    if (editModal === false) {
      setErrStart("");
      setErrSale("");
      setErrEnd("");
      setErrRange("");
      setErrPrice("");
      setErrBundle("");
      setErrStatus("");
      setMessage("");
    }
  }

  const setID = () => {
    props.handleEdit("");
  };

  const onEdit = () => {
    let params = {
      date: campaignDate,
    };
    axios
      .post(campaignUrl, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response, "res");
        let output = response.data.data;
        let msg = response.data.message;
        console.log("data", output);

        //filter by id
     
        if (output) {
          if (output.filter((data) => data._id === props.id).length !== 0) {
            output
              .filter((data) => data._id === props.id)
              .map((val) => {
                setStartDate(val.startDate);
                setEndDate(val.endDate);
                campaignData.start = val.startDate;
                campaignData.end = val.endDate;
                var xyz =
                  val.bundle.length &&
                  val.bundle.map((itm, inx) =>
                    setSelectBundle({
                      label: itm.bundleName,
                      value: itm._id,
                    })
                  );
                console.log("yay", campaignData);
                console.log("final", xyz);
                console.log("end-start", val.startDate, val.endDate);
                campaignData.bundle = val.bundle;
                campaignData.endPrice = val.endPrice;
                campaignData.sale = val.salesTarget;
                campaignData.status = val.status;
                campaignData.price = val.startingPrice;
                return true;
              });
            edittoggle();
            return true;
          }
        } else {
          return msg;
        }
      });
  };

  const getBundle = () => {
    axios
      .get(process.env.React_App_API_URL + `/bundle/getAllBundle?limit=1000`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log("aaa", response);
        let output = response.data.data;
        console.log("bundles", output);
        setBundle(output);
      });
  };

  const editHandler = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    if (campaignData !== "") {
      campaignData.bundle = selectBundle;
      campaignData.start = startDate;
      campaignData.end = endDate;
      setcampaignData({
        ...campaignData,
        bundle: selectBundle,
        start: startDate,
        end: endDate,
      });
      setcampaignData({
        ...campaignData,
        [name]: val,
      });
    } else return;
  };

  const handleSubmit = () => {
    console.log("campaignData, strt, end", campaignData, startDate, endDate);
    axios
      .post(
        process.env.React_App_API_URL + `/campaign/updateCampaign/${ID}`,
        {
          startingPrice: parseInt(campaignData.price),
          bundle: selectBundle.value,
          startDate: dateFormat(startDate, "isoDateTime"),
          endDate: dateFormat(endDate, "isoDateTime"),
          salesTarget: campaignData.sale,
          endPrice: parseInt(campaignData.endPrice),
          status: campaignData.status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (props.page == 0) {
          props.getData(props.page + 1);
        }
        props.getData(props.page);
        edittoggle();
        setID();
      })
      .catch((error) => {
        console.log(error);
        props.getData();
        let err = error.response.data.errors;
        console.log(err, "err");
        let message = error.response.data.message;
        setMessage(message);
        if (err) {
          err.filter((val) => {
            if (val.param === "startDate") {
              setErrStart(val.msg);
            }
            if (val.param === "endDate") {
              setErrEnd(val.msg);
            }
            if (val.param === "endPrice") {
              setErrRange(val.msg);
            }
            if (val.param === "salesTarget") {
              setErrSale(val.msg);
            }

            if (val.param === "bundle") {
              setErrBundle(val.msg);
            }

            if (val.param === "startingPrice") {
              setErrPrice(val.msg);
            }
            if (val.param === "status") {
              setErrStatus(val.msg);
            }
          });
        }
      });
  };

  const handleStart = (date) => {
    console.log(777,date);
    setStartDate(date);
  };

  const handleEnd = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    getBundle();
    onEdit(props.id);
  }, [props.id]);

  useEffect(() => {}, [campaignData]);

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email === null) {
      navigate("/admin/login");
    }
  }, []);

  Bundle.map((info, index) => {
    bundleArray.push({
      label: info.bundleName,
      value: info._id,
    });
    return true;
  });
  return (
    <div>
      <Modal isOpen={editModal} toggle={edittoggle}>
        <ModalHeader>Edit Campaign Details!</ModalHeader>
        <ModalBody>
          <Form className="form">
            {/* <!-- Text Input--> */}
            <div className="row">
              {/* <!-- Area Chart --> */}
              <div className="col-4">
                <div className="form-group">
                  <label className="control-label" for="start_date">
                    START DATE
                  </label>
                  <DatePicker
                    value={typeof startDate === "string" ? new Date(startDate) : startDate}
                    format="yyyy/MM/dd"
                    required
                    onChange={(date) => handleStart(date)}
                  />
                  {errStart && <span style={{ color: "red" }}>{errStart}</span>}
                </div>
              </div>
              <div className="col-4">
                {/* <!-- Text Input--> */}
                <div className="form-group">
                  <label className="control-label" for="end">
                    END DATE{" "}
                  </label>
                  <DatePicker
                    minDate={new Date()}
                    value={ typeof endDate === "string" ? new Date(endDate) : endDate}
                    format="yyyy/MM/dd"
                    onChange={(date) => handleEnd(date)}
                  />
                  {errEnd && (
                    <span style={{ color: "red" }}>{errEnd}Invalid</span>
                  )}
                </div>
              </div>
              <div className="col-4">
                {/* <!-- Select Basic --> */}
                <div className="form-group">
                  <label className="control-label" for="bundle">
                    BUNDLE
                  </label>
                  <Select
                    defaultValue={selectBundle}
                    options={bundleArray}
                    onChange={(e) => setSelectBundle(e)}
                  ></Select>
                  {errBundle && (
                    <span style={{ color: "red" }}>{errBundle}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                {/* <!-- Text Input--> */}
                <div className="form-group">
                  <label className="control-label" for="price">
                    STARTING PRICE
                  </label>
                  <Input
                    id="starting_price"
                    name="price"
                    placeholder="STARTING PRICE"
                    className="form-control Input-md"
                    defaultValue={campaignData.price}
                    onChange={editHandler}
                    required=""
                    type="number"
                  />
                  {errPrice && <span style={{ color: "red" }}>{errPrice}</span>}
                </div>
              </div>
              <div className="col-6">
                {/* <!-- Text Input--> */}
                <div className="form-group">
                  <label className="control-label" for="sale">
                    SALE TARGET
                  </label>
                  <Input
                    id="sale_target"
                    name="sale"
                    placeholder="SALE TARGET"
                    className="form-control Input-md"
                    defaultValue={campaignData.sale}
                    onChange={editHandler}
                    required=""
                    type="text"
                  />
                  {errSale && <span style={{ color: "red" }}>{errSale}</span>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                {/* <!-- Text Input--> */}
                <div className="form-group">
                  <label className="control-label" for="product_description">
                    END PRICE
                  </label>
                  <Input
                    defaultValue={campaignData.endPrice}
                    onChange={editHandler}
                    id="price_range"
                    name="endPrice"
                    type="text"
                    placeholder="END PRICE"
                    className="form-control Input-md"
                    required=""
                  />
                  {errRange && <span style={{ color: "red" }}>{errRange}</span>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                {/* <!-- Text Input--> */}
                <div className="form-group">
                  <FormGroup>
                    <label className="control-label" for="approuved_by">
                      STATUS
                    </label>
                    <Input id="exampleSelect" value={campaignData.status} name="status" type="select" onChange={editHandler}>
                      <option value={"activate"}>Activate</option>
                      <option value={"deactivate"}>Deactivate</option>
                    </Input>
                  </FormGroup>
                  {errStatus && (
                    <span style={{ color: "red" }}>{errStatus}</span>
                  )}
                </div>
              </div>
            </div>

            <p style={{ color: "red" }}>{message}</p>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="Button"
            onClick={edittoggle}
            className="btn btn-danger"
            value="Submit"
            data-dismiss="modal"
          >
            Close
          </Button>
          <Button
            id="singleButton"
            name="singleButton"
            onClick={handleSubmit}
            color="primary"
            className="btn btn-primary"
          >
            submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditCampaign;
