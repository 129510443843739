import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "reactstrap";

const Completed = () => {
    // let { id } = useParams();
    const [data, setData] = useState([{}]);
    const url = "https://jsonplaceholder.typicode.com/todos";

    // functions
    const getData = () => {
        axios.get(url).then(response => {
            console.log("data", response.data);
            setData(response.data);
        })
            .catch(error => {
                console.log(error);
            });

    }
    useEffect(() => {
        getData();
    }, [])

    return (
        <div>
            < Table striped bordered hover dark>

                <thead>
                    <tr>

                        <th scope="col">Sr. No</th>
                        <th scope="col">User ID</th>
                        <th scope="col">Title</th>
                        <th scope="col">Completed</th>

                    </tr>
                </thead>
                <tbody>



                    {data.length > 0 ?
                        [...data].map((item, index) =>
                            item.completed === true ?(

                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>{item.completed}</td>
                            </tr>
                            ) : ""
                            )
                        
                        :
                        "No Record Found"
                    }
                </tbody>
            </Table>
        </div>
    );
}
export default Completed;