import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, Input } from "reactstrap";
import LogOut from "./logout";
import i1 from "../assets/img/i1.jpg";
import i2 from "../assets/img/i2.jpg";
import i3 from "../assets/img/i3.jpg";
import i4 from "../assets/img/i4.jpg";
import i5 from "../assets/img/i5.jpg";
import i6 from "../assets/img/i6.jpg";
import i7 from "../assets/img/i7.jpg";
import i8 from "../assets/img/i8.jpg";

const Cart = () => {
    const cartUrl = process.env.React_App_API_URL + `/cart/getProducts`;
    const [data, setData] = useState([]);
    const [searchField, setSearchField] = useState(false);
    const token = localStorage.getItem('token');
    const deleteCart = process.env.React_App_API_URL + `/cart/delete/`;
    const [totalAmt, setTotalAmt] = useState(0);

    const openSearch = () => {
        setSearchField(!searchField);
    }

    const getData = () => {
        axios.get(cartUrl, {
            headers: {
                Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQDEyMy5jb20iLCJpZCI6IjYzZDM2ZTliNzhlMjczZmVhOGE2YWU4NSIsImlhdCI6MTY4MDUxMDI3MX0.svcUrVkhofeGu1MwJaorz8y7lPMPWFF0NZ1V1oHezDk"}`,
            }
        }).then(function (response) {
            console.log("cart Res", response);
            let output = response.data.data;
            setData(output);
            setTotalAmt(response.data.totalAmmount);
        })
    }

    const deleteCartData = (id) => {
        axios.post(deleteCart + id, {
            headers: {
                Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQDEyMy5jb20iLCJpZCI6IjYzZDM2ZTliNzhlMjczZmVhOGE2YWU4NSIsImlhdCI6MTY4MDUxMDI3MX0.svcUrVkhofeGu1MwJaorz8y7lPMPWFF0NZ1V1oHezDk"}`,
            }
        }).then(function (response) {
            console.log("delete", response);
            getData();

        }).catch(error => {
            console.log(error);
            getData();
        })
    }


    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <header className="header_area sticky-header">
                <div className="main_menu">
                    <nav className="navbar navbar-expand-lg navbar-light main_box">
                        <div className="container">
                            {/* <!-- Brand and toggle get grouped for better mobile display --> */}
                            <Link to="/user/dashboard" className="navbar-brand logo_h"><img src="img/logo.png" alt="" /></Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
                            <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                                <ul className="nav navbar-nav menu_nav ml-auto">
                                    <li className="nav-item"><Link to="/user/dashboard" className="nav-link" >Home</Link></li>
                                    <li className="nav-item submenu dropdown active">
                                        <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false">Shop</Link>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><Link to="category.html" className="nav-link">Shop Category</Link></li>
                                            <li className="nav-item"><Link to="single-product.html" className="nav-link" >Product Details</Link></li>
                                            <li className="nav-item"><Link to="/user/checkout" className="nav-link">Product Checkout</Link></li>
                                            <li className="nav-item active"><Link to="#" className="nav-link" >Shopping Cart</Link></li>
                                            <li className="nav-item"><Link to="confirmation.html" className="nav-link" >Confirmation</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item submenu dropdown">
                                        <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false">Pages</Link>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><Link to="/user/login" className="nav-link">Login</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><Link to="contact.html" className="nav-link">Contact</Link></li>
                                </ul>
                                <ul className="nav navbar-nav navbar-right">
                                    <li className="nav-item"><Link to="#" className="cart"><span className="ti-bag"></span></Link></li>
                                    <li className="nav-item">
                                        <Button className="search" onClick={openSearch}><span className="lnr lnr-magnifier" id="search"></span></Button>
                                    </li>
                                    <LogOut />
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                {searchField == true ?
                    (<div className="search_input" id="search_input_box">
                        <div className="container">
                            <form className="d-flex justify-content-between">
                                <Input type="text" className="form-control" id="search_input" placeholder="Search Here" />
                                <Button type="submit" onClick={openSearch} className="btn"> <span className="lnr lnr-cross" id="close_search" title="Close Search"></span></Button>

                            </form>
                        </div>
                    </div>) : ""}
            </header>
            {/* 
	<!-- End Header Area --> */}
            <section className="banner-area organic-breadcrumb">
                <div className="container">
                    <div className="breadcrumb-banner d-flex flex-wrap align-items-center justify-content-end">
                        <div className="col-first">
                            <h1>Shopping Cart</h1>
                            <nav className="d-flex align-items-center">
                                <Link to="/user/dashboard">Home<span className="lnr lnr-arrow-right"></span></Link>
                                <Link to="/user/cart">Cart</Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cart_area">
                <div className="container">
                    <div className="cart_inner">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Remove from cart</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                       data && data.length > 0 ?
                                        data.filter(itm => itm.productId !== null).map((item,index)=>
                                        // data.map((item, index) =>
                                            <tr key={index}>
                                                <td> <div className="media">
                                                    <div className="d-flex">
                                                        <img src={"http://peak-discount-api.imenso.in/ProductImg/" + item.productId.productImg} alt="" width="60" />
                                                        </div>
                                                    <div className="media-body">
                                                        <p>{item.productId.productName}</p>
                                                    </div>
                                                </div>
                                                </td>  
                                                <td>
                                                    <h5><i class="fa fa-rupee"></i>{item.price}</h5>
                                                </td>
                                                <td>
                                                    <h5>{item.quantity}</h5>
                                                </td>
                                                <td><i class="fa fa-rupee"></i>{item.total}</td>
                                                <th scope="col" className="text-center">
                                                    <button className="btn btn-default btn-sm" onClick={() => { deleteCartData(item._id) }}><i className="far fa-trash-alt"></i></button>
                                                </th>
                                            </tr>
                                        //   :""}
                                        )
                                        :
                                        <tr>
                                            Empty Cart
                                        </tr>

                                    }

                                    <tr>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            <h5>Subtotal</h5>
                                        </td>
                                        <td>
                                            <h5><i class="fa fa-rupee"></i>{totalAmt}</h5>
                                        </td>
                                    </tr>
                                    <tr className="shipping_area">
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            <h5>Shipping</h5>
                                        </td>
                                        <td>
                                            <div className="shipping_box">
                                                <ul className="list">
                                                    <li><Link to="#">Flat Rate: <i class="fa fa-rupee"></i>50.00</Link></li>
                                                    {/* <li><Link to="#">Free Shipping</Link></li>
                                                    <li><Link to="#">Flat Rate: <i class="fa fa-rupee"></i>10.00</Link></li>
                                                    <li className="active"><Link to="#">Local Delivery: <i class="fa fa-rupee"></i>2.00</Link></li> */}
                                                </ul>
                                                {/* <h6>Calculate Shipping <i className="fa fa-caret-down" aria-hidden="true"></i></h6>
                                                <select className="shipping_select">
                                                    <option value="1">Bangladesh</option>
                                                    <option value="2">India</option>
                                                    <option value="4">Pakistan</option>
                                                </select>
                                                <select className="shipping_select">
                                                    <option value="1">Select a State</option>
                                                    <option value="2">Select a State</option>
                                                    <option value="4">Select a State</option>
                                                </select>
                                                <input type="text" placeholder="Postcode/Zipcode" />
                                                <Link to="#" className="gray_btn" >Update Details</Link> */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="out_button_area">
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            <div className="checkout_btn_inner d-flex align-items-center">
                                                <Link to="/user/dashboard" className="gray_btn">Continue Shopping</Link>
                                                <Link to="/user/checkout" className="primary-btn" >Proceed to checkout</Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table >
                        </div >
                    </div >
                </div >
            </section >

            <footer className="footer-area section_gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3  col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h6>About Us</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                                    ut labore dolore
                                    magna aliqua.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4  col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h6>Newsletter</h6>
                                <p>Stay update with our latest</p>
                                <div className="" id="mc_embed_signup">

                                    <form target="_blank" novalidate="true" action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                                        method="get" className="form-inline">

                                        <div className="d-flex flex-row">

                                            <input className="form-control" name="EMAIL" placeholder="Enter Email" onfocus="this.placeholder = ''"
                                                onblur="this.placeholder = 'Enter Email '" required="" type="email" />


                                            <button className="click-btn btn btn-default"><i className="fa fa-long-arrow-right"
                                                aria-hidden="true"></i></button>
                                            <div style={{ position: "absolute", left: -5000 }}>
                                                <input name="b_36c4fd991d266f23781ded980_aefe40901a" tabindex="-1" value=""
                                                    type="text" />
                                            </div>

                                            {/* <!-- <div className="col-lg-4 col-md-4">
													<button className="bb-btn btn"><span className="lnr lnr-arrow-right"></span></button>
												</div>  --> */}
                                        </div>
                                        <div className="info"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3  col-md-6 col-sm-6">
                            <div className="single-footer-widget mail-chimp">
                                <h6 className="mb-20">Instragram Feed</h6>
                                <ul className="instafeed d-flex flex-wrap">
                                <li><img src={i1} alt="" /></li>
                                    <li><img src={i2} alt="" /></li>
                                    <li><img src={i3} alt="" /></li>
                                    <li><img src={i4} alt="" /></li>
                                    <li><img src={i5} alt="" /></li>
                                    <li><img src={i6} alt="" /></li>
                                    <li><img src={i7} alt="" /></li>
                                    <li><img src={i8} alt="" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h6>Follow Us</h6>
                                <p>Let us be social</p>
                                <div className="footer-social d-flex align-items-center">
                                    <Link to="#"><i className="fa fa-facebook"></i></Link>
                                    <Link to="#"><i className="fa fa-twitter"></i></Link>
                                    <Link to="#"><i className="fa fa-dribbble"></i></Link>
                                    <Link to="#"><i className="fa fa-behance"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom d-flex justify-content-center align-items-center flex-wrap">
                        <p className="footer-text m-0">
                            {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                            Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart-o" aria-hidden="true"></i> by <Link to="https://colorlib.com" target="_blank">Colorlib</Link>
                            {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )

}
export default Cart;