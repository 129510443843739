import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Button, Input } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import login from '../assets/img/login.jpg';
import i1 from "../assets/img/i1.jpg";
import i2 from "../assets/img/i2.jpg";
import i3 from "../assets/img/i3.jpg";
import i4 from "../assets/img/i4.jpg";
import i5 from "../assets/img/i5.jpg";
import i6 from "../assets/img/i6.jpg";
import i7 from "../assets/img/i7.jpg";
import i8 from "../assets/img/i8.jpg";
import ForgetPswd from './forgetPswd';
import LogOut from './logout';

const Confirmation = () => {
    const [searchField, setSearchField] = useState(false);

    const openSearch = () => {
        setSearchField(!searchField);
    }
  
    return (<>
        <header class="header_area sticky-header">
            <div class="main_menu">
                <nav class="navbar navbar-expand-lg navbar-light main_box">
                    <div class="container">
                        <a class="navbar-brand logo_h" href="index.html"><img src="img/logo.png" alt=""/></a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <div class="collapse navbar-collapse offset" id="navbarSupportedContent">
                            <ul class="nav navbar-nav menu_nav ml-auto">
                                <li class="nav-item"><a class="nav-link" href="/user/dashboard">Home</a></li>
                                <li class="nav-item submenu dropdown active">
                                    <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                        aria-expanded="false">Shop</a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a class="nav-link" href="category.html">Shop Category</a></li>
                                        <li class="nav-item"><a class="nav-link" href="single-product.html">Product Details</a></li>
                                        <li class="nav-item"><a class="nav-link" href="user/checkout">Product Checkout</a></li>
                                        <li class="nav-item"><a class="nav-link" href="/user/cart">Shopping Cart</a></li>
                                        <li class="nav-item active"><a class="nav-link" href="/user/confirmation">Confirmation</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item submenu dropdown">
                                    <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                        aria-expanded="false">Pages</a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a class="nav-link" href="/user/login">Login</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a class="nav-link" href="contact.html">Contact</a></li>
                            </ul>
                            <ul class="nav navbar-nav navbar-right">
                                <li class="nav-item"><a href="/user/cart" class="cart"><span class="ti-bag"></span></a></li>
                                <li className="nav-item">
                                        <Button className="search" onClick={openSearch}><span className="lnr lnr-magnifier" id="search"></span></Button>
                                    </li>
                                    <LogOut />
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            {searchField == true ?
                    (<div className="search_input" id="search_input_box">
                        <div className="container">
                            <form className="d-flex justify-content-between">
                                <Input type="text" className="form-control" id="search_input" placeholder="Search Here" />
                                <Button type="submit" onClick={openSearch} className="btn"> <span className="lnr lnr-cross" id="close_search" title="Close Search"></span></Button>

                            </form>
                        </div>
                    </div>) : ""}
        </header>
        <section class="banner-area organic-breadcrumb">
		<div class="container">
			<div class="breadcrumb-banner d-flex flex-wrap align-items-center justify-content-end">
				<div class="col-first">
					<h1>Confirmation</h1>
					<nav class="d-flex align-items-center">
						<a href="/user/dashboard">Home<span class="lnr lnr-arrow-right"></span></a>
						<a href="#">Confirmation</a>
					</nav>
				</div>
			</div>
		</div>
	</section>

	<section class="order_details section_gap">
		<div class="container">
			<h3 class="title_confirmation">Thank you. Your order has been received.</h3>		
		</div>
	</section>
    <footer class="footer-area section_gap">
		<div class="container">
			<div class="row">
				<div class="col-lg-3  col-md-6 col-sm-6">
					<div class="single-footer-widget">
						<h6>About Us</h6>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore dolore
							magna aliqua.
						</p>
					</div>
				</div>
				<div class="col-lg-4  col-md-6 col-sm-6">
					<div class="single-footer-widget">
						<h6>Newsletter</h6>
						<p>Stay update with our latest</p>
						<div class="" id="mc_embed_signup">

							<form target="_blank" novalidate="true" action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
							 method="get" class="form-inline">

								<div class="d-flex flex-row">

									<input class="form-control" name="EMAIL" placeholder="Enter Email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Email '"
									 required="" type="email"/>


									<button class="click-btn btn btn-default"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
									<div style={{position: "absolute", left: -5000 }}>
										<input name="b_36c4fd991d266f23781ded980_aefe40901a" tabindex="-1" value="" type="text"/>
									</div>

									
								</div>
								<div class="info"></div>
							</form>
						</div>
					</div>
				</div>
				<div class="col-lg-3  col-md-6 col-sm-6">
					<div class="single-footer-widget mail-chimp">
						<h6 class="mb-20">Instragram Feed</h6>
						<ul class="instafeed d-flex flex-wrap">
							<li><img src={i1} alt=""/></li>
							<li><img src={i2} alt=""/></li>
							<li><img src={i3} alt=""/></li>
							<li><img src={i4} alt=""/></li>
							<li><img src={i5} alt=""/></li>
							<li><img src={i6} alt=""/></li>
							<li><img src={i7} alt=""/></li>
							<li><img src={i8} alt=""/></li>
						</ul>
					</div>
				</div>
				<div class="col-lg-2 col-md-6 col-sm-6">
					<div class="single-footer-widget">
						<h6>Follow Us</h6>
						<p>Let us be social</p>
						<div class="footer-social d-flex align-items-center">
							<a href="#"><i class="fa fa-facebook"></i></a>
							<a href="#"><i class="fa fa-twitter"></i></a>
							<a href="#"><i class="fa fa-dribbble"></i></a>
							<a href="#"><i class="fa fa-behance"></i></a>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-bottom d-flex justify-content-center align-items-center flex-wrap">
				<p class="footer-text m-0">
Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i class="fa fa-heart-o" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a>

</p>
			</div>
		</div>
	</footer>
    </>)

}
export default Confirmation;