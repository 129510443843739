import { useEffect, useState } from "react";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import { Button, Input } from "reactstrap";
import { image } from "../assets/img/exportImage";
import LogOut from "./logout";
import dateFormat from "dateformat";
import {FormGroup,Label} from "reactstrap";



const UserDashboard = () => {
	const [cartId, setCartId] = useState([]);
	const uid = localStorage.getItem("userID");
	const [displayList, setdisplayList] = useState([]);
	const [searchField, setSearchField] = useState(false);
	const [cartValue, setCartValue] = useState(false);
	const [val, setVal] = useState("Add to Bag");
	const [status, setStatus] = useState("Add to Bag");
	const [campaignList, setCampaignList] = useState([]);
	const date = dateFormat(new Date(), "isoDateTime");
	const splitNewDate = date.split('T')[0];
	console.log(999,splitNewDate);
	const [campaignDate, setCampaignDate] = useState(splitNewDate);
	const [added, setAdded] = useState("Add to Cart");
	const [preCampainList,setPreCampainList] = useState([]);

	const openSearch = () => {
		setSearchField(!searchField);
	}

	const getData = () => {
		axios.get(process.env.React_App_API_URL + `/product/getAllProduct?limit=${5}`, {
			headers: {
				Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQDEyMy5jb20iLCJpZCI6IjYzZDM2ZTliNzhlMjczZmVhOGE2YWU4NSIsImlhdCI6MTY4MDUxMDI3MX0.svcUrVkhofeGu1MwJaorz8y7lPMPWFF0NZ1V1oHezDk"}`,
				"Content-Type": "multipart/form-data"
			}
		}).then(function (response) {
			let data = response.data.data;
			let width = ["500px", "400px", "350px", "550px", "300px"];
			let height = ["400px", "400px", "400px", "300px", "300px"];
			console.log("dataa", data);
			const addSize = data?.map((item, index) => {
				return { ...item, width: width[index] ? width[index] : "400px", height: height[index] ? height[index] : "400px" }
			})
			setdisplayList(addSize);
		})
	}
	useEffect(()=>{
		getCampaign();
	},[campaignDate])

	const getCampaign = () => {
		let params = {
			date: campaignDate
		}
		axios.post(process.env.React_App_API_URL + `/campaign/getCampaign?limit=${2000}`, params, {
			headers: {
				Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQDEyMy5jb20iLCJpZCI6IjYzZDM2ZTliNzhlMjczZmVhOGE2YWU4NSIsImlhdCI6MTY4MDUxMDI3MX0.svcUrVkhofeGu1MwJaorz8y7lPMPWFF0NZ1V1oHezDk"}`,
				// "Content-Type": "multipart/form-data"
			}
		})
			.then(function (response) {
				let data = response.data.data;
				// setCampaignProduct(data);
              console.log(999,data);
			  if(data)
			  setPreCampainList(data);
				const newArray = [];
				 data?.map((item)=>{
					item.bundle[0].content?.map((item)=>{
						newArray.push(item)
				 })
			});
				
				setCampaignList(newArray);	

			})
	}

	useEffect(() => {
		getData();
		cartStatus();
	}, [])

	const handleCart = (prodId, campId, price, qnty, stat) => {
		console.log(7777,campId);
		// let selectedProduct= cartId;
		// selectedProduct.push(id);
		  setCartValue(!cartValue);
		  cartId.push(prodId);
		// setCartId([...cartId,id]);
		// setCartId({selectedProduct})
		  cartStatus(prodId, campId, price, qnty, stat)
	}

	const cartStatus = (prodId, campId, price, qnty, stat) => {
		if (cartId.includes(prodId)) {
			setVal("added");

			console.log("cart_id", cartId);
		}
		addToCart(prodId, campId, price, qnty, stat);
	}

	const addToCart = (prodID, campID, price, qnty, stat) => {

		axios.post('http://peak-discount-api.imenso.in/cart/add-to-cart',
			{
				productId: prodID,
				quantity: 1,
				campaignId: campID,
				total: parseInt(price) * parseInt(qnty),
				price: price,
				userId: uid,
				status: stat

			}, {
			headers: {
				Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQDEyMy5jb20iLCJpZCI6IjYzZDM2ZTliNzhlMjczZmVhOGE2YWU4NSIsImlhdCI6MTY4MDUxMDI3MX0.svcUrVkhofeGu1MwJaorz8y7lPMPWFF0NZ1V1oHezDk"}`,
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
			.then((response) => {
				console.log("ad cart res", response);
				if (response.status === 200) {

					setAdded(response.data.message);
				}
				getData();
				getCampaign();
			})
			.catch(error => {
				console.log(error);

			})
	}



	useEffect(() => {
		getCampaign();
		// addToCart();

	}, [cartId])
	useEffect(() => {
		console.log("----------------", campaignList)
	}, [campaignList])


	return (
		<>
			{/* <!-- Start Header Area --> */}
			<header className="header_area sticky-header">
				<div className="main_menu">
					<nav className="navbar navbar-expand-lg navbar-light main_box">
						<div className="container">
							{/* <!-- Brand and toggle get grouped for better mobile display --> */}
							<Link to="/user/dashboard" className="navbar-brand logo_h" ><img src={image.logo} alt="" /></Link>
							<Button className="navbar-toggler" type="Button" data-toggle="collapse" data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</Button>
							{/* <!-- Collect the nav links, forms, and other content for toggling --> */}
							<div className="collapse navbar-collapse offset" id="navbarSupportedContent">
								<ul className="nav navbar-nav menu_nav ml-auto">
									<li className="nav-item active"><Link to="/user/dashboard" className="nav-link" >Home</Link></li>
									<li className="nav-item submenu dropdown">
										<Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="Button" aria-haspopup="true"
											aria-expanded="false">Shop</Link>
										<ul className="dropdown-menu">
											<li className="nav-item"><Link to="" className="nav-link" >Shop Category</Link></li>
											<li className="nav-item"><Link to="" className="nav-link" >Product Details</Link></li>
											<li className="nav-item"><Link to="" className="nav-link" >Product Checkout</Link></li>
											<li className="nav-item"><Link to="" className="nav-link" >Shopping Cart</Link></li>
											<li className="nav-item"><Link to="" className="nav-link">Confirmation</Link></li>
										</ul>
									</li>
									<li className="nav-item submenu dropdown">
										<Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="Button" aria-haspopup="true"
											aria-expanded="false">Pages</Link>
										<ul className="dropdown-menu">
										</ul>
									</li>
									<li className="nav-item"><Link to="/user/order" className="nav-link">Order History</Link></li>
								</ul>
								<ul className="nav navbar-nav navbar-right">
									<li className="nav-item"><Link to="/user/cart" className="cart"><span className="ti-bag"></span></Link></li>
									<li className="nav-item">
										<Button className="search" onClick={openSearch}><span className="lnr lnr-magnifier" id="search"></span></Button>
									</li>
									<LogOut />
								</ul>
							</div>
						</div>
					</nav>
				</div>
				{searchField == true ?
					(<div className="search_input" id="search_input_box">
						<div className="container">
							<form className="d-flex justify-content-between">
								<Input type="text" className="form-control" id="search_input" placeholder="Search Here" />
								<Button type="submit" onClick={openSearch} className="btn"> <span className="lnr lnr-cross" id="close_search" title="Close Search"></span></Button>

							</form>
						</div>
					</div>) : ""}
			</header>

			<section className="banner-area">

				<div className="container">
					<div className="row fullscreen align-items-center justify-content-start" style={{ height: "100vh" }}>
						<div className="col-lg-12">
							<div className="active-banner-slider owl-carousel">
								{/* <!-- single-slide --> */}
								<Carousel showThumbs={false} infiniteLoop={true} showArrows={true} autoPlay={true} >

									<div className="row single-slide align-items-center d-flex">
										<div className="col-lg-5 col-md-6">
											<div className="banner-content">
												<h1>Nike New <br />Collection!</h1>
												<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
												<div className="add-bag d-flex align-items-center">

													<Link to="" className="add-btn" ><span className="lnr lnr-cross"></span></Link>
													<span className="add-text text-uppercase">Add to Bag</span>
												</div>
											</div>
										</div>
										<div className="col-lg-7">
											<div className="banner-img">
												<img className="img-fluid" src={image.banner} alt="" />
											</div>
										</div>
									</div>
									<div className="row single-slide">
										<div className="col-lg-5">
											<div className="banner-content">
												<h1>Nike New <br />Collection!</h1>
												<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
												<div className="add-bag d-flex align-items-center">
													<Link to="" className="add-btn" ><span className="lnr lnr-cross"></span></Link>
													<span className="add-text text-uppercase">Add to Bag</span>
												</div>
											</div>
										</div>
										<div className="col-lg-7">
											<div className="banner-img">
												<img className="img-fluid" src={image.banner} alt="" />
											</div>
										</div>
									</div>

								</Carousel>
							</div>
						</div>
					</div>
				</div>

			</section>

			{/* <!-- End banner Area --> */}
			{/* <!-- start features Area --> */}
			<section className="features-area section_gap">
				<div className="container">
					<div className="row features-inner">
						{/* <!-- single features --> */}
						<div className="col-lg-3 col-md-6 col-sm-6">
							<div className="single-features">
								<div className="f-icon">
									<img src={image.icon1} alt="" />
								</div>
								<h6>Free Delivery</h6>
								<p>Free Shipping on all order</p>
							</div>
						</div>
						{/* <!-- single features --> */}
						<div className="col-lg-3 col-md-6 col-sm-6">
							<div className="single-features">
								<div className="f-icon">
									<img src={image.icon2} alt="" />
								</div>
								<h6>Return Policy</h6>
								<p>Free Shipping on all order</p>
							</div>
						</div>
						{/* <!-- single features --> */}
						<div className="col-lg-3 col-md-6 col-sm-6">
							<div className="single-features">
								<div className="f-icon">
									<img src={image.icon3} alt="" />
								</div>
								<h6>24/7 Support</h6>
								<p>Free Shipping on all order</p>
							</div>
						</div>
						{/* <!-- single features --> */}
						<div className="col-lg-3 col-md-6 col-sm-6">
							<div className="single-features">
								<div className="f-icon">
									<img src={image.icon4} alt="" />
								</div>
								<h6>Secure Payment</h6>
								<p>Free Shipping on all order</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- end features Area --> */}
			{/* <!-- Start category Area --> */}

			<section className="category-area mb-5" >
				{/* <div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 col-md-12"> */}
				{/* <div className="row"></div>
							<div className="col-lg-8 col-md-8"> */}
				<div style={{ display: "flex", flexWrap: "wrap", width: "80%", margin: "0px auto" }}>
					{displayList.length > 0 ?
						displayList.map((data, index) =>
								<div className="single-deal" style={{ marginRight: "25px",width:data.width,height:data.height}}>
									<div className="overlay"></div>
									<img src={"http://peak-discount-api.imenso.in/ProductImg/" + data.productImg} width={data.width} height={data.height} />
									<Link to="#" className="img-pop-up" target="_blank">
										<div className="deal-details">
											<h6 className="deal-title">Sneaker for Sports</h6>
										</div>
									</Link>
								</div>

						)
						:
						<tr>
							No Record Found
						</tr>

					}</div>
				{/* </div> */}
				{/* </div>
					</div>

				</div> */}
			</section>




			<section className="category-area mb-5" >
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 col-md-12">
							<div className="row">
								<div className="col-lg-8 col-md-8">
									<div className="single-deal">
										<div className="overlay"></div>
										<img className="img-fluid w-100" src={image.category1} alt="" />
										<Link to="#" className="img-pop-up" target="_blank">
											<div className="deal-details">
												<h6 className="deal-title">Sneaker for Sports</h6>
											</div>
										</Link>
									</div>
								</div>
								<div className="col-lg-4 col-md-4">
									<div className="single-deal">
										<div className="overlay"></div>
										<img className="img-fluid w-100" src={image.category2} alt="" />
										<Link to="#" className="img-pop-up" target="_blank">
											<div className="deal-details">
												<h6 className="deal-title">Sneaker for Sports</h6>
											</div>
										</Link>
									</div>
								</div>
								<div className="col-lg-4 col-md-4">
									<div className="single-deal">
										<div className="overlay"></div>
										<img className="img-fluid w-100" src={image.category3} alt="" />
										<Link to="#" className="img-pop-up" target="_blank">
											<div className="deal-details">
												<h6 className="deal-title">Product for Couple</h6>
											</div>
										</Link>
									</div>
								</div>
								<div className="col-lg-8 col-md-8">
									<div className="single-deal">
										<div className="overlay"></div>
										<img className="img-fluid w-100" src={image.category4} alt="" />
										<Link to="#" className="img-pop-up" target="_blank">
											<div className="deal-details">
												<h6 className="deal-title">Sneaker for Sports</h6>
											</div>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-deal">
								<div className="overlay"></div>
								<img className="img-fluid w-100" src={image.category5} alt="" />
								<Link to="#" className="img-pop-up" target="_blank">
									<div className="deal-details">
										<h6 className="deal-title">Sneaker for Sports</h6>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- End category Area -->

<!-- start product Area --> */}

			{/* <!-- single product slide --> */}
			<Carousel showThumbs={false} infiniteLoop={true} showArrows={true} autoPlay={true} >

				<div className="single-product-slider"><div className="row justify-content-center">
					<div className="col-lg-6 text-center">
						<div className="section-title">
							<h1>Latest Products</h1>
							<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
								dolore
								magna aliqua.</p>
								<FormGroup className="w-25 m-auto">
    <Label for="exampleDate">
    <strong className="d-2"><h4>Date of Campaign</h4></strong>  
    </Label>
    <Input
      id="exampleDate"
      name="date"
      placeholder="date placeholder"
      type="date"
	  value={campaignDate}
	  onChange={(e)=>{setCampaignDate(e.target.value)}}
    />
  </FormGroup>

						</div>
					</div>
				</div>

					<div className="container">
						<div className="row">
							{
								campaignList && campaignList.length > 0 ?
									campaignList.map((info,index) => <div key={index} className="col-lg-3 col-md-6">
													<div className="single-product">
														<img className="img-fluid" src={"http://peak-discount-api.imenso.in/ProductImg/" + info.productImg} alt="image not available" width="60" />
														<div className="product-details">
															<h6>{info.productName}</h6>
															<div className="price">
																<h6><i class="fa fa-rupee"></i>{info.productPrice}</h6>
															</div>
															<div className="prd-bottom">
																<Link to="" className="social-info">
					
																	<Button onClick={() => handleCart(info?._id,preCampainList[index]._id , info.productPrice, info.quantity, info.addedToCart)} className="btn btn-default btn-sm"> <i className="ti-bag">
																	{console.log("add", info.productName, info.addedToCart,)}
																	{info._id && info.addedToCart == "false" ?

																		<p className="hover-text">Add to Bag</p>
																		:
																		<div>
																			<p className="hover-text">Added</p>
																		</div>
																	}</i></Button>
																</Link>
																<Link to="" className="social-info">
																	<span className="lnr lnr-heart"></span>
																	<p className="hover-text">Wishlist</p>
																</Link>
																<Link to="" className="social-info">
																	<span className="lnr lnr-move"></span>
																	<p className="hover-text">view more</p>
																</Link>
															</div>
														</div>
													</div>
												</div>
											

										


									)

									:
									<div className="col-lg-6 text-center">
										<strong>Products will be available soon, stay tuned!</strong>
									</div>

							}
						</div>
					</div>

				</div>
			</Carousel>

			<section className="exclusive-deal-area">
				<div className="container-fluid">
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6 no-padding exclusive-left">
							<div className="row clock_sec clockdiv" id="clockdiv">
								<div className="col-lg-12">
									<h1>Exclusive Hot Deal Ends Soon!</h1>
									<p>Who are in extremely love with eco friendly system.</p>
								</div>
								<div className="col-lg-12">
									<div className="row clock-wrap">
										<div className="col clockinner1 clockinner">
											<h1 className="days">150</h1>
											<span className="smalltext">Days</span>
										</div>
										<div className="col clockinner clockinner1">
											<h1 className="hours">23</h1>
											<span className="smalltext">Hours</span>
										</div>
										<div className="col clockinner clockinner1">
											<h1 className="minutes">47</h1>
											<span className="smalltext">Mins</span>
										</div>
										<div className="col clockinner clockinner1">
											<h1 className="seconds">59</h1>
											<span className="smalltext">Secs</span>
										</div>
									</div>
								</div>
							</div>
							<Link to="" className="primary-btn">Shop Now</Link>
						</div>
						<div className="col-lg-6 no-padding exclusive-right">
							<div className="active-exclusive-product-slider">
								{/* <!-- single exclusive carousel --> */}
								<div className="single-exclusive-slider">
									<img className="img-fluid" src={image.ep1} alt="" />
									<div className="product-details">
										<div className="price">
											<h6><i class="fa fa-rupee"></i>150.00</h6>
											<h6 className="l-through"><i class="fa fa-rupee"></i>210.00</h6>
										</div>
										<h4>addidas New Hammer sole
											for Sports person</h4>
										<div className="add-bag d-flex align-items-center justify-content-center">
											<Link className="add-btn" to=""><span className="ti-bag"></span></Link>
											<span className="add-text text-uppercase">Add to Bag</span>
										</div>
									</div>
								</div>
								{/* <!-- single exclusive carousel --> */}
								<div className="single-exclusive-slider">
									<img className="img-fluid" src={image.ep1} alt="" />
									<div className="product-details">
										<div className="price">
											<h6><i class="fa fa-rupee"></i>150.00</h6>
											<h6 className="l-through"><i class="fa fa-rupee"></i>210.00</h6>
										</div>
										<h4>addidas New Hammer sole
											for Sports person</h4>
										<div className="add-bag d-flex align-items-center justify-content-center">
											<Link className="add-btn" to=""><span className="ti-bag"></span></Link>
											<span className="add-text text-uppercase">Add to Bag</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="brand-area section_gap">
				<div className="container">
					<div className="row">
						<Link className="col single-img" to="#">
							<img className="img-fluid d-block mx-auto" src={image.brand1} alt="" />
						</Link>
						<Link className="col single-img" to="#">
							<img className="img-fluid d-block mx-auto" src={image.brand2} alt="" />
						</Link>
						<Link className="col single-img" to="#">
							<img className="img-fluid d-block mx-auto" src={image.brand3} alt="" />
						</Link>
						<Link className="col single-img" to="#">
							<img className="img-fluid d-block mx-auto" src={image.brand4} alt="" />
						</Link>
						<Link className="col single-img" to="#">
							<img className="img-fluid d-block mx-auto" src={image.brand5} alt="" />
						</Link>
					</div>
				</div>
			</section>

			<section className="related-product-area section_gap_bottom">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-6 text-center">
							<div className="section-title">
								<h1>Deals of the Week</h1>
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
									magna aliqua.</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-9">
							<div className="row">
								<div className="col-lg-4 col-md-4 col-sm-6 mb-20">
									<div className="single-related-product d-flex">
										<Link to="#"><img src={image.r1} alt="" /></Link>
										<div className="desc">
											<Link to="#" className="title">Black lace Heels</Link>
											<div className="price">
												<h6><i class="fa fa-rupee"></i>189.00</h6>
												<h6 className="l-through"><i class="fa fa-rupee"></i>210.00</h6>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-6 mb-20">
									<div className="single-related-product d-flex">
										<Link to="#"><img src={image.r2} alt="" /></Link>
										<div className="desc">
											<Link to="#" className="title">Black lace Heels</Link>
											<div className="price">
												<h6><i class="fa fa-rupee"></i>189.00</h6>
												<h6 className="l-through"><i class="fa fa-rupee"></i>210.00</h6>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-6 mb-20">
									<div className="single-related-product d-flex">
										<Link to="#"><img src={image.r3} alt="" /></Link>
										<div className="desc">
											<Link to="#" className="title">Black lace Heels</Link>
											<div className="price">
												<h6><i class="fa fa-rupee"></i>189.00</h6>
												<h6 className="l-through"><i class="fa fa-rupee"></i>210.00</h6>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-6 mb-20">
									<div className="single-related-product d-flex">
										<Link to="#"><img src={image.r5} alt="" /></Link>
										<div className="desc">
											<Link to="#" className="title">Black lace Heels</Link>
											<div className="price">
												<h6><i class="fa fa-rupee"></i>189.00</h6>
												<h6 className="l-through"><i class="fa fa-rupee"></i>210.00</h6>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-6 mb-20">
									<div className="single-related-product d-flex">
										<Link to="#"><img src={image.r6} alt="" /></Link>
										<div className="desc">
											<Link to="#" className="title">Black lace Heels</Link>
											<div className="price">
												<h6><i class="fa fa-rupee"></i>189.00</h6>
												<h6 className="l-through"><i class="fa fa-rupee"></i>210.00</h6>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-6 mb-20">
									<div className="single-related-product d-flex">
										<Link to="#"><img src={image.r7} alt="" /></Link>
										<div className="desc">
											<Link to="#" className="title">Black lace Heels</Link>
											<div className="price">
												<h6><i class="fa fa-rupee"></i>189.00</h6>
												<h6 className="l-through"><i class="fa fa-rupee"></i>210.00</h6>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-6">
									<div className="single-related-product d-flex">
										<Link to="#"><img src={image.r9} alt="" /></Link>
										<div className="desc">
											<Link to="#" className="title">Black lace Heels</Link>
											<div className="price">
												<h6><i class="fa fa-rupee"></i>189.00</h6>
												<h6 className="l-through"><i class="fa fa-rupee"></i>210.00</h6>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-6">
									<div className="single-related-product d-flex">
										<Link to="#"><img src={image.imager10} alt="" /></Link>
										<div className="desc">
											<Link to="#" className="title">Black lace Heels</Link>
											<div className="price">
												<h6><i class="fa fa-rupee"></i>189.00</h6>
												<h6 className="l-through"><i class="fa fa-rupee"></i>210.00</h6>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-6">
									<div className="single-related-product d-flex">
										<Link to="#"><img src={image.r11} alt="" /></Link>
										<div className="desc">
											<Link to="#" className="title">Black lace Heels</Link>
											<div className="price">
												<h6><i class="fa fa-rupee"></i>189.00</h6>
												<h6 className="l-through"><i class="fa fa-rupee"></i>210.00</h6>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="ctg-right">
								<Link to="#" target="_blank">
									<img className="img-fluid d-block mx-auto" src={image.category} alt="" />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>


			<footer className="footer-area section_gap">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-6 col-sm-6">
							<div className="single-footer-widget">
								<h6>About Us</h6>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore dolore
									magna aliqua.
								</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-6">
							<div className="single-footer-widget">
								<h6>Newsletter</h6>
								<p>Stay update with our latest</p>
								<div className="" id="mc_embed_signup">

									<form target="_blank" noValidate={true} action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
										method="get" className="form-inline">

										<div className="d-flex flex-row">

											<Input className="form-control" name="EMAIL" placeholder="Enter Email" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter Email '"
												required="" type="email" />

											<Button className="click-btn btn btn-default"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></Button>
											<div style={{ position: "absolute", left: -5000 }}>
												<Input name="b_36c4fd991d266f23781ded980_aefe40901a" tabIndex="-1" type="text" />
											</div>

										</div>
										<div className="info"></div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-sm-6">
							<div className="single-footer-widget mail-chimp">
								<h6 className="mb-20">Instragram Feed</h6>
								<ul className="instafeed d-flex flex-wrap">
									<li><img src={image.i1} alt="" /></li>
									<li><img src={image.i2} alt="" /></li>
									<li><img src={image.i3} alt="" /></li>
									<li><img src={image.i4} alt="" /></li>
									<li><img src={image.i5} alt="" /></li>
									<li><img src={image.i6} alt="" /></li>
									<li><img src={image.i7} alt="" /></li>
									<li><img src={image.i8} alt="" /></li>
								</ul>
							</div>
						</div>
						<div className="col-lg-2 col-md-6 col-sm-6">
							<div className="single-footer-widget">
								<h6>Follow Us</h6>
								<p>Let us be social</p>
								<div className="footer-social d-flex align-items-center">
									<Link to="#"><i className="fa fa-facebook"></i></Link>
									<Link to="#"><i className="fa fa-twitter"></i></Link>
									<Link to="#"><i className="fa fa-dribbble"></i></Link>
									<Link to="#"><i className="fa fa-behance"></i></Link>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom d-flex justify-content-center align-items-center flex-wrap">
						<p className="footer-text m-0">
							Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart-o" aria-hidden="true"></i> by <Link to="https://colorlib.com" target="_blank">Colorlib</Link>
							{/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
						</p>
					</div>
				</div>
			</footer>
		</>
	);
}
export default UserDashboard;
