import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "reactstrap";

const LogOut = () => {
    const navigate = useNavigate();
    const handleLogOut = () => {
        localStorage.clear();
        navigate("/");
    }

    useEffect(() => {
        const email = localStorage.getItem('user_email');
        if (email === null) {
            navigate('/');
        }
    }, [])

    return (
        <li className="nav-item">
            <Button className="search" onClick={handleLogOut}><span className="lnr lnr-exit"></span></Button>
        </li>

    );
}
export default LogOut;