import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import { Table, Button, Form, Input } from 'reactstrap';
import CreateCampaign from "./create";
import EditCampaign from "./edit";
import LogOut from "../logout";
import DatePicker from 'react-date-picker';
import { getDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import Select from 'react-select';
import dateFormat, { masks } from "dateformat";
import moment from 'moment'

const Campaign = () => {

  // useState
  const email = localStorage.getItem('email');
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [campaign, setCampaign] = useState([]);
  const [editId, setEditId] = useState(0);
  const [searchText, setSearchText] = useState("");
  // const [campaignDate, setCampaignDate]=useState("");
  const date= dateFormat(new Date(), "isoDateTime");
  const splitNewDate= date.split('T')[0];
  const splitDate=splitNewDate.split("-").join("/");
  const [campaignDate, setCampaignDate]=useState(dateFormat(new Date(splitDate), "isoDateTime").split("T")[0]);
  const [totalPages, setPages] = useState(0);
  const [displayList, setDisplayList] = useState([]);
  const [sendPage, setSendPage] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectBundle, setSelectBundle] = useState("");
  const [data, setdata] = useState({ start: startDate, end: endDate, bundle: selectBundle, price: 0, sale: "", endPrice:0, status: "" });
  const campaignUrl = process.env.React_App_API_URL + '/campaign/createCampaign';
  const bundleUrl = process.env.React_App_API_URL + '/bundle/getAllBundle?limit=1000';
  const [campaignData, setCampaignData] = useState([]);
  const campaignArray = [];
  const [errStart, setErrStart] = useState("");
  const [errSale, setErrSale] = useState("");
  const [errEnd, setErrEnd] = useState("");
  const [errPrice, setErrPrice] = useState("");
  const [errRange, setErrRange] = useState("");
  const [errBundle, setErrBundle] = useState("");
  const [errStatus, setErrStatus] = useState("");
  const [selectedDate,setSelectedDate] = useState();
  
  getDefaultLocale(es);
  useEffect(()=>{
    getData();
  },[selectedDate]);
  // console.log("hello world",selectedDate)
  const getData = (currentPage) => {
    console.log("api call",selectedDate);
    let params = {
      date: selectedDate
  }
    axios.post(process.env.React_App_API_URL + `/campaign/getCampaign?limit=5&page=${currentPage}&search=${searchText}`, params, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(function (response) {
      console.log(444,response);
      let data = response.data.data;
      if(data)
      {
        setCampaign(data);
        setDisplayList(data);
        setPages(response.data.totalPages);
      }
      else{
        setDisplayList("");
      }
     

    })
  }


  const onDelete = (id) => {
    console.log("delid", id);
    axios.post(process.env.React_App_API_URL + `/campaign/deleteCampaign/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        if(sendPage== 0)
        {
            getData(sendPage +1);
        }
        else{getData(sendPage);
        }
      })
      .catch(function (error) {
        console.log(error);
        console.log(sendPage, "pageno");
        if(sendPage== 0)
        {
            getData(sendPage +1);
        }
        else{getData(sendPage);
        }
      });
  }

  const handleEdit = (id) => {
    localStorage.setItem('camp_id', id);
    setEditId(id);
  }

  const handleDelete = (id) => {
    onDelete(id);
  }

  const searchHandler = (e) => {
    var text = e.target.value;
    if (text == "") {
      navigate("/admin/campaign");
    }
    else {
      setSearchText(text);
    }
  }


  useEffect(() => {
    getData();
    if (email === null) {
      navigate('/admin/login');
    }
  }, [])

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;
    const pagesFromServer = getData(currentPage);
    setSendPage(currentPage);
    console.log(pagesFromServer, "pagesFromServer");
    setCampaign(pagesFromServer);
  }

  console.log(campaign, "camp");
  useEffect(() => {
    getBundle();
  }, [data])

  const handleChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    data.bundle = selectBundle;
    data.start = startDate;
    data.end = endDate;

    setdata({
      ...data,
      bundle: selectBundle,
      start: startDate,
      end: endDate
    })
    setdata({
      ...data,
      [name]: val,
    })
  }

  const getBundle = () => {
    axios.get(bundleUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(function (response) {
      let data = response.data.data;
      if(data)
      setCampaignData(data);
      // console.log(222, data);
    })
  }

  const handleSubmit = () => {
    postData();
    data.reset()
  }

  // to create campaign
  const postData = () => {
    console.log(campaignArray, "campaignArray");
    console.log("data", data);
    let params = {
      startingPrice: data.price,
      bundle: selectBundle,
      startDate:dateFormat(startDate, "isoDateTime"),
      endDate: dateFormat(endDate, "isoDateTime"),
      salesTarget: data.sale,
      endPrice: data.endPrice,
      status: data.status
    }

    axios.post(campaignUrl,
      params, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          setdata({ start: startDate, end: endDate, bundle: selectBundle, price: 0, sale: "", endPrice: 0, status: "" });
          setStartDate(new Date());
          setEndDate(new Date());
          setSelectBundle("");
        }
        getData();
      })
      .catch(error => {
        console.log(error);
        let err = error.response.data.errors;
        console.log(err, "err");

        err.filter((val) => {
          if (val.param == "startDate") {
            setErrStart(val.msg);
          }
          if (val.param == "endDate") {
            setErrEnd(val.msg);
          }
          if (val.param === "endPrice") {
            setErrRange(val.msg);
          }
          if (val.param === "salesTarget") {
            setErrSale(val.msg);
          }
          if (val.param === "bundle") {
            setErrBundle(val.msg);
          }
          if (val.param === "startingPrice") {
            setErrPrice(val.msg);
          }

          return true;
        })
      })
  }

  campaignData.map((info, index) => {
    campaignArray.push({
      label: info.bundleName,
      value: info._id
    }
    )
    return true;
  }
  )

  return (
    <>

      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
          {/* <!-- Sidebar - Brand --> */}
          <Link to="/admin/dashboard" className="sidebar-brand d-flex align-items-center justify-content-center" >
            <div className="sidebar-brand-icon rotate-n-15">
              <i className="fas fa-laugh-wink"></i>
            </div>
            <div className="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
          </Link>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider my-0" />
          {/* <!-- Nav Item - Dashboard --> */}
          <li className="nav-item active">
            <Link to="/admin/dashboard" className="nav-link" >
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span></Link>
          </li>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider" />
          {/* <!-- Heading --> */}
          <div className="sidebar-heading"> Campaign Page </div>
          <li className="nav-item">
            <Link to="/admin/product" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Products Page</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/bundle" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Bundle Page</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/campaign" className="nav-link">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Campaign Page</span>
            </Link>
          </li>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider" />
        </ul>
        {/* <!-- End of Sidebar --> */}
        {/* <!-- Content Wrapper --> */}

        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              {/* <!-- Sidebar Toggle (Topbar) --> */}
              <Button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars"></i>
              </Button>
              {/* <!-- Topbar Search --> */}

              {/* <Form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div className="Input-group">
                  <Input type="text"
                    className="form-control bg-light border-0 small"
                    aria-label="Search" aria-describedby="basic-addon2"
                    id="outlined-basic"
                    onChange={searchHandler}
                    onKeyPress={getData}
                    variant="outlined"
                    placeholder="search for..."
                    label="Search"
                  />
                  <div Name="input-group-append">
                    <Button color="primary" className="btn btn-primary" type="Button" onClick={getData}> <i className="fas fa-search fa-sm"></i>
                    </Button>
                  </div>
                </div>
              </Form> */}
              <LogOut />
              {/* <!-- Topbar Navbar --> */}
              
            </nav>
            {/* <!-- End of Topbar --> */}
            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <div>
                  {/* <!-- Button to Open the Modal --> */}
                  {/* <Button type="Button" className="btn btn-sm btn-primary shadow-sm" color="primary" data-toggle="modal" data-target="#addProduct"><i className="fas fa-plus fa-sm text-white-50"></i> Add Campaign</Button> */}
                  <CreateCampaign getData={getData} setSelectedDate={setSelectedDate}/></div>
              </div>
              {/* <!-- Content Row --> */}

              <EditCampaign handleEdit={handleEdit} id={editId} getData={getData} page={sendPage} />

              {/* <!-- Content Row --> */}
              <div className="row">
                {/* <!-- Area Chart --> */}
                <div className="col-xl-12">
                  <div className="card shadow mb-4">
                    {/* <!-- Card Header - Dropdown --> */}
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary"> Campaign list </h6>
                    </div>
                    {/* <!-- Card Body --> */}
                    <div className="card-body">
                      <br />
                      < Table striped hover>

                        <thead>
                          <tr>

                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col">Bundle</th>
                            <th scope="col">Starting Price </th>
                            <th scope="col">Sale Target</th>
                            <th scope="col">End Price</th>
                            <th scope="col">Status</th>
                            <th scope="col" width="100" class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayList && displayList.length > 0 ?
                            displayList?.map((data, index) =>
                              <tr key={index}>
                                <td>{data.startDate.split('T')[0]}</td>
                                <td>{data.endDate.split('T')[0]}</td>
                                <td>{data.bundle.length && data.bundle.map((itm, inx) =>
                                  <p key={inx}>{itm.bundleName}</p>
                                )} </td>
                                <td>{data.startingPrice}</td>
                                <td>{data.salesTarget}</td>
                                <td>{data.endPrice}</td>
                                <td><Button className={`btn ${data.status=="activate"?"btn-success":"btn-danger"} text-capitalize`} >{data.status}</Button></td>
                                <button class="btn btn-default btn-sm" onClick={() => { handleEdit(data._id) }}><i class="fas fa-pencil-alt"></i></button>
                                <button class="btn btn-default btn-sm" onClick={() => { handleDelete(data._id) }}><i class="far fa-trash-alt"></i></button>
                              </tr>
                            )
                            :
                            <tr><td colSpan={8} align="center"><h3>No Record Found</h3></td></tr>
                          }
                        </tbody>
                      </Table>
                      {/* <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        pageCount={totalPages}
                        breakLabel="..."
                        pageRangedisplayListed={2}
                        renderOnZeroPageCount={null}
                        breakClassName={"page-link"}
                        breakLinkClassName={"pagination-link"}
                        marginPagesdisplayListed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-link"}
                        previousLinkClassName={"pagination__link"}
                        nextClassName={"page-link"}
                        nextLinkClassName={"pagination__link"}
                        subContainerClassName={'pages pagination'}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"active"}
                      /> */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          {/* <!-- Footer --> */}
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright © Your Website 2021</span>
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>

      {/*<!-- The Modal -->*/}
      <div className="modal fade" id="addProduct">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {/* <!-- Modal Header --> */}
            <div className="modal-header">
              <h4 className="modal-title">Add a New Campaign!</h4>
              <Button type="Button" className="close" data-dismiss="modal">&times;</Button>
            </div>

            {/* <!-- Modal body --> */}

            <div className="modal-body">
              <form className="form">
                {/* <!-- Text Input--> */}
                <div className="row">
                  {/* <!-- Area Chart --> */}
                  <div className="col-4">
                    <div className="form-group">

                      <label className="control-label" for="start_date">START DATE</label>
                      <DatePicker locale={es} minDate={new Date()} value={startDate} dateFormat='yyyy/MM/dd' required="" onChange={(date) => setStartDate(date)}
                      />
                      {errStart && <span style={{ color: "red" }}>{errStart}</span>}
                    </div>
                  </div>
                  <div className="col-4">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">
                      <label className="control-label" for="end">END DATE </label>
                      <DatePicker value={endDate} minDate={new Date()} dateFormat='yyyy/MM/dd' onChange={(date) => setEndDate(date)} />
                      {errEnd && <span style={{ color: "red" }}>{errEnd}Invalid</span>}
                    </div>
                  </div>
                  <div className="col-4">
                    {/* <!-- Select Basic --> */}
                    <div className="form-group">

                      <label className="control-label" for="bundle">BUNDLE</label>
                      <Select defaultValue={selectBundle} options={campaignArray} onChange={(e) => setSelectBundle(e.value)} id="bundle" name="bundle" className="form-control">
                      </Select>
                      {errBundle && <span style={{ color: "red" }}>{errBundle}</span>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">

                      <label className="control-label" for="price">STARTING PRICE</label>t
                      <Input id="starting_price" name="price" placeholder="STARTING PRICE" className="form-control Input-md" onChange={handleChange} required="" type="number" />
                      {errPrice && <span style={{ color: "red" }}>{errPrice}</span>}
                    </div>
                  </div>
                  <div className="col-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">
                      <label className="control-label" for="sale">SALE TARGET</label>
                      <Input id="sale_target" name="sale" placeholder="SALE TARGET" className="form-control Input-md" onChange={handleChange} required="" type="text" />
                      {errSale && <span style={{ color: "red" }}>{errSale}</span>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">

                      <label className="control-label" for="product_description">END PRICE</label>

                      <Input id="price_range" name="endPrice" type="number" placeholder="END PRICE" className="form-control Input-md" onChange={handleChange} required="" />
                      {errRange && <span style={{ color: "red" }}>{errRange}</span>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">
                      <label className="control-label" for="approuved_by">STATUS</label>
                      <Input id="status" onChange={handleChange} name="status" placeholder="STATUS" className="form-control Input-md" required="" type="text" />
                      {errStatus && <span style={{ color: "red" }}>{errStatus}</span>}
                    </div>
                  </div>
                </div>

              </form>
            </div>

            {/* <!-- Modal footer --> */}
            <div className="modal-footer">
              <Button type="Button" className="btn btn-danger" data-dismiss="modal">Close</Button>
              <Button id="singleButton" name="singleButton" color="primary" onClick={handleSubmit} className="btn btn-primary">submit</Button>
            </div>
          </div>
        </div>
      </div>


    </>
  );

}
export default Campaign;