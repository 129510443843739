import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input } from "reactstrap";
import LogOut from "./logout";
import i1 from "../assets/img/i1.jpg";
import i2 from "../assets/img/i2.jpg";
import i3 from "../assets/img/i3.jpg";
import i4 from "../assets/img/i4.jpg";
import i5 from "../assets/img/i5.jpg";
import i6 from "../assets/img/i6.jpg";
import i7 from "../assets/img/i7.jpg";
import i8 from "../assets/img/i8.jpg";

const Checkout = () => {
    const [searchField, setSearchField] = useState(false);
    const token = localStorage.getItem('token');
    const checkoutUrl = process.env.React_App_API_URL + `/buy/buy-product`;
    const [data, setData] = useState([]);
    const cartUrl = process.env.React_App_API_URL + `/cart/getProducts`;
    const [subtotal, setSubtotal] = useState(0);
    const navigate = useNavigate();
    const openSearch = () => {
        setSearchField(!searchField);
    }

    const confirmation = () => {
        axios.post(process.env.React_App_API_URL + '/purchase/buyProduct',
            {
                headers: {
                    Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQDEyMy5jb20iLCJpZCI6IjYzZDM2ZTliNzhlMjczZmVhOGE2YWU4NSIsImlhdCI6MTY4MDUxMDI3MX0.svcUrVkhofeGu1MwJaorz8y7lPMPWFF0NZ1V1oHezDk"}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((response) => {
                console.log("res", response);
                navigate("/user/confirmation");
            })
            .catch(error => {
                console.log(error);
                let err = error.response.data.errors;
                let message = error.response.data.message;
                // setMsg(message);
                navigate("/user/confirmation");
                console.log("message", message);
                console.log(err);
            })
    }

    const getData = () => {
        axios.get(cartUrl, {
            headers: {
                Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQDEyMy5jb20iLCJpZCI6IjYzZDM2ZTliNzhlMjczZmVhOGE2YWU4NSIsImlhdCI6MTY4MDUxMDI3MX0.svcUrVkhofeGu1MwJaorz8y7lPMPWFF0NZ1V1oHezDk"}`,
            }
        }).then(function (response) {
            console.log("cart Res", response);
            let output = response.data.data;
            setSubtotal(response.data.totalAmmount);
            setData(output);
        })
    }

    const postData = () => {
        axios.get(checkoutUrl, {
            headers: {
                Authorization: `Bearer  ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQDEyMy5jb20iLCJpZCI6IjYzZDM2ZTliNzhlMjczZmVhOGE2YWU4NSIsImlhdCI6MTY4MDUxMDI3MX0.svcUrVkhofeGu1MwJaorz8y7lPMPWFF0NZ1V1oHezDk"}`,
            }
        }).then(function (response) {
            console.log("cart Res", response);
        })
    }

    useEffect(() => {
        getData();
        postData();
    }, [])

    return (
        <>
            <header className="header_area sticky-header">
                <div className="main_menu">
                    <nav className="navbar navbar-expand-lg navbar-light main_box">
                        <div className="container">
                            {/* <!-- Brand and toggle get grouped for better mobile display --> */}
                            <Link to="/user/dashboard" className="navbar-brand logo_h" ><img src="img/logo.png" alt="" /></Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
                            <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                                <ul className="nav navbar-nav menu_nav ml-auto">
                                    <li className="nav-item"><Link to="/user/dashboard" className="nav-link" >Home</Link></li>
                                    <li className="nav-item submenu dropdown active">
                                        <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false">Shop</Link>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><Link to="category.html" className="nav-link">Shop Category</Link></li>
                                            <li className="nav-item"><Link to="single-product.html" className="nav-link" >Product Details</Link></li>
                                            <li className="nav-item active"><Link to="/user/checkout" className="nav-link" >Product Checkout</Link></li>
                                            <li className="nav-item"><Link to="/user/cart" className="nav-link">Shopping Cart</Link></li>
                                            <li className="nav-item"><Link to="confirmation.html" className="nav-link" >Confirmation</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item submenu dropdown">
                                        <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                                            aria-expanded="false">Pages</Link>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><Link to="/user/login" className="nav-link">Login</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><Link to="contact.html" className="nav-link">Contact</Link></li>
                                </ul>
                                <ul className="nav navbar-nav navbar-right">
                                    <li className="nav-item"><Link to="/user/cart" className="cart"><span className="ti-bag"></span></Link></li>
                                    <li className="nav-item">
                                        <Button className="search" onClick={openSearch}><span className="lnr lnr-magnifier" id="search"></span></Button>
                                    </li>
                                    <LogOut />
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                {searchField == true ?
                    (<div className="search_input" id="search_input_box">
                        <div className="container">
                            <form className="d-flex justify-content-between">
                                <Input type="text" className="form-control" id="search_input" placeholder="Search Here" />
                                <Button type="submit" onClick={openSearch} className="btn"> <span className="lnr lnr-cross" id="close_search" title="Close Search"></span></Button>

                            </form>
                        </div>
                    </div>) : ""}
            </header>
            <section className="banner-area organic-breadcrumb">
                <div className="container">
                    <div className="breadcrumb-banner d-flex flex-wrap align-items-center justify-content-end">
                        <div className="col-first">
                            <h1>Checkout</h1>
                            <nav className="d-flex align-items-center">
                                <Link to="/user/dashboard">Home<span className="lnr lnr-arrow-right"></span></Link>
                                <Link to="#">Checkout</Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="checkout_area section_gap">
                <div className="container">
                    {/* <div className="returning_customer">
                        <div className="check_title">
                            <h2>Returning Customer? <Link to="/user/login">Click here to login</Link></h2>
                        </div>
                        <p>If you have shopped with us before, please enter your details in the boxes below. If you are a new
                            customer, please proceed to the Billing & Shipping section.</p>
                        <form className="row contact_form" action="#" method="post" novalidate="noValidate">
                            <div className="col-md-6 form-group p_star">
                                <Input type="text" className="form-control" id="name" name="name" />
                                <span className="placeholder" data-placeholder="Username or Email"></span>
                            </div>
                            <div className="col-md-6 form-group p_star">
                                <Input type="password" className="form-control" id="password" name="password" />
                                <span className="placeholder" data-placeholder="Password"></span>
                            </div>
                            <div className="col-md-12 form-group">
                                <button type="submit" value="submit" className="primary-btn">login</button>
                                <div className="creat_account">
                                    <Input type="checkbox" id="f-option" name="selector" />
                                    <label for="f-option">Remember me</label>
                                </div>
                                <Link to="#" className="lost_pass" href="#">Lost your password?</Link>
                            </div>
                        </form>
                    </div> */}
                    {/* <div className="cupon_area">
                        <div className="check_title">
                            <h2>Have a coupon? <Link to="#">Click here to enter your code</Link></h2>
                        </div>
                        <Input type="text" placeholder="Enter coupon code" />
                        <Link to="#" className="tp_btn">Apply Coupon</Link>
                    </div> */}
                    <div className="billing_details">
                        <div className="row">
                            <div className="col-lg-8">
                                {/* <h3>Billing Details</h3> */}
                                {/* <form className="row contact_form" action="#" method="post" novalidate="novalidate">
                                    <div className="col-md-6 form-group p_star">
                                        <Input type="text" className="form-control" id="first" name="name" />
                                        <span className="placeholder" data-placeholder="First name"></span>
                                    </div>
                                    <div className="col-md-6 form-group p_star">
                                        <Input type="text" className="form-control" id="last" name="name" />
                                        <span className="placeholder" data-placeholder="Last name"></span>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <Input type="text" className="form-control" id="company" name="company" placeholder="Company name" />
                                    </div>
                                    <div className="col-md-6 form-group p_star">
                                        <Input type="text" className="form-control" id="number" name="number" />
                                        <span className="placeholder" data-placeholder="Phone number"></span>
                                    </div>
                                    <div className="col-md-6 form-group p_star">
                                        <Input type="text" className="form-control" id="email" name="compemailany" />
                                        <span className="placeholder" data-placeholder="Email Address"></span>
                                    </div>
                                    <div className="col-md-12 form-group p_star">
                                        <select className="country_select">
                                            <option value="1">Country</option>
                                            <option value="2">Country</option>
                                            <option value="4">Country</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12 form-group p_star">
                                        <Input type="text" className="form-control" id="add1" name="add1" />
                                        <span className="placeholder" data-placeholder="Address line 01"></span>
                                    </div>
                                    <div className="col-md-12 form-group p_star">
                                        <Input type="text" className="form-control" id="add2" name="add2" />
                                        <span className="placeholder" data-placeholder="Address line 02"></span>
                                    </div>
                                    <div className="col-md-12 form-group p_star">
                                        <Input type="text" className="form-control" id="city" name="city" />
                                        <span className="placeholder" data-placeholder="Town/City"></span>
                                    </div>
                                    <div className="col-md-12 form-group p_star">
                                        <select className="country_select">
                                            <option value="1">District</option>
                                            <option value="2">District</option>
                                            <option value="4">District</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <Input type="text" className="form-control" id="zip" name="zip" placeholder="Postcode/ZIP" />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <div className="creat_account">
                                            <Input type="checkbox" id="f-option2" name="selector" />
                                            <label for="f-option2">Create an account?</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <div className="creat_account">
                                            <h3>Shipping Details</h3>
                                            <Input type="checkbox" id="f-option3" name="selector" />
                                            <label for="f-option3">Ship to a different address?</label>
                                        </div>
                                        <textarea className="form-control" name="message" id="message" rows="1" placeholder="Order Notes"></textarea>
                                    </div>
                                </form> */}
                            </div>
                            <div className="col-lg-4">
                                <div className="order_box">
                                    <h2>Your Order</h2>
                                    <ul className="list">
                                        <li><Link to="#">Product <span>Total</span></Link></li>
                                        {
                                            data && data.length > 0 ?
                                                data.filter(itm => itm.productId !== null).map((item, index) =>
                                                    <li><Link to="#">{item.productId.productName} <span className="middle">x {item.quantity}</span> <span className="last"><i class="fa fa-rupee"></i>{item.total}</span></Link></li>
                                                )
                                                :
                                                <div>
                                                    No Product Selected
                                                </div>}
                                    </ul>



                                    <ul className="list list_2">
                                        <li><Link to="#">Subtotal <span><i class="fa fa-rupee"></i>{subtotal}</span></Link></li>
                                        <li><Link to="#">Shipping <span>Flat rate: <i class="fa fa-rupee"></i>50.00</span></Link></li>
                                        <li><Link to="#">Total <span><i class="fa fa-rupee"></i>{subtotal + 50}</span></Link></li>
                                    </ul>
                                    <div className="payment_item">
                                        <div className="radion_btn">
                                            <Input type="radio" id="f-option5" name="selector" />
                                            <label for="f-option5">Check payments</label>
                                            <div className="check"></div>
                                        </div>
                                        <p>Please send a check to Store Name, Store Street, Store Town, Store State / County,
                                            Store Postcode.</p>
                                    </div>
                                    <div className="payment_item active">
                                        <div className="radion_btn">
                                            <Input type="radio" id="f-option6" name="selector" />
                                            <label for="f-option6">Paypal </label>
                                            <img src="img/product/card.jpg" alt="" />
                                            <div className="check"></div>
                                        </div>
                                        <p>Pay via PayPal; you can pay with your credit card if you don’t have a PayPal
                                            account.</p>
                                    </div>
                                    <div className="creat_account">
                                        <Input type="checkbox" id="f-option4" name="selector" />
                                        <label for="f-option4">I’ve read and accept the </label>
                                        <Link to="#">terms & conditions*</Link>
                                    </div>
                                    <Button className="primary-btn" onClick={confirmation}>Proceed to Paypal</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="footer-area section_gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3  col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h6>About Us</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                                    ut labore dolore
                                    magna aliqua.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4  col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h6>Newsletter</h6>
                                <p>Stay update with our latest</p>
                                <div className="" id="mc_embed_signup">

                                    <form target="_blank" novalidate="true" action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                                        method="get" className="form-inline">

                                        <div className="d-flex flex-row">

                                            <Input className="form-control" name="EMAIL" placeholder="Enter Email" onFocus="this.placeholder = ''"
                                                onBlur="this.placeholder = 'Enter Email '" required="" type="email" />


                                            <button className="click-btn btn btn-default"><i className="fa fa-long-arrow-right"
                                                aria-hidden="true"></i></button>
                                            <div style={{ position: "absolute", left: -5000 }}>
                                                <Input name="b_36c4fd991d266f23781ded980_aefe40901a" tabIndex="-1" value=""
                                                    type="text" />
                                            </div>
                                        </div>
                                        <div className="info"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3  col-md-6 col-sm-6">
                            <div className="single-footer-widget mail-chimp">
                                <h6 className="mb-20">Instragram Feed</h6>
                                <ul className="instafeed d-flex flex-wrap">
                                    <li><img src={i1} alt="" /></li>
                                    <li><img src={i2} alt="" /></li>
                                    <li><img src={i3} alt="" /></li>
                                    <li><img src={i4} alt="" /></li>
                                    <li><img src={i5} alt="" /></li>
                                    <li><img src={i6} alt="" /></li>
                                    <li><img src={i7} alt="" /></li>
                                    <li><img src={i8} alt="" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h6>Follow Us</h6>
                                <p>Let us be social</p>
                                <div className="footer-social d-flex align-items-center">
                                    <Link to="#"><i className="fa fa-facebook"></i></Link>
                                    <Link to="#"><i className="fa fa-twitter"></i></Link>
                                    <Link to="#"><i className="fa fa-dribbble"></i></Link>
                                    <Link to="#"><i className="fa fa-behance"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom d-flex justify-content-center align-items-center flex-wrap">
                        <p className="footer-text m-0">
                            Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart-o" aria-hidden="true"></i> by <Link to="https://colorlib.com" target="_blank">Colorlib</Link>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default Checkout;