import React from "react";
import { Link } from 'react-router-dom';
import { Button } from "reactstrap";

const SideBarTemplate = () => {

  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

      {/* <!-- Sidebar - Brand --> */}
      <Link to = "/admin/dashboard" className="sidebar-brand d-flex align-items-center justify-content-center" >
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-laugh-wink"></i>
        </div>
        <div className="sidebar-brand-text mx-3">Admin Panel</div>
      </Link>

      {/* <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/* <!-- Nav Item - Dashboard --> */}


      {/* <!-- Divider --> */}
      <hr className="sidebar-divider" />


      {/* <!-- Nav Item - Pages Collapse Menu --> */}
      <li className="nav-item active">
        {/* <Link to='/about'>Go to Aboutpage</Link> */}
        <Link to ="#" className="nav-link collapsed" data-toggle="collapse" data-target="#collapsePages"
          aria-expanded="true" aria-controls="collapsePages">
          <i className="fas fa-fw fa-tachometer-alt"></i>
          <span>Pages</span></Link>

        <div id="collapsePages" className="collapse show" aria-labelledby="headingPages" data-parent="#accordionSidebar">
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Login Screens:</h6>
            <Link to = "/admin/product" className="collapse-item" >Products</Link>
            <Link to = "/admin/bundle" className="collapse-item" >Bundles</Link>
            <Link to = "/admin/campaign"  className="collapse-item" >Campaign</Link>
          </div>
        </div>
      </li>



      {/* <!-- Divider --> */}
      <hr className="sidebar-divider d-none d-md-block" />

      {/* <!-- Sidebar Toggler (Sidebar) --> */}
      <div className="text-center d-none d-md-inline">
        <Button className="rounded-circle border-0" id="sidebarToggle"></Button>
      </div>
    </ul>

  );
}
export default SideBarTemplate;