import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./components/Admin/login";
import Product from "./components/Admin/Product";
import EditProduct from "./components/Admin/Product/edit";
import CreateProduct from "./components/Admin/Product/create";
import AdminPanel from "./components/Admin/adminpanel";
import NavbarTemplate from "./components/Admin/navbar";
import SideBarTemplate from "./components/Admin/sideBar";
import MainContent from "./components/Admin/mainContent";
import Bundle from "./components/Admin/Bundle";
import CreateBundle from "./components/Admin/Bundle/create";
import Campaign from "./components/Admin/Campaign";
import CreateCampaign from "./components/Admin/Campaign/create";
import EditCampaign from "./components/Admin/Campaign/edit";
import LogOut from "./components/Admin/logout";
import UserLogin from './components/User/login';
import Signup from './components/User/signup';

import NewAPI from "./components/newAPI";
import HomePage from "./components/newTask";
import Completed from "./components/newTask/completed";
import ChildComponent from "./components/newTask/child";
import ParentComponent from "./components/newTask/parent";
import NewChildComponent from "./components/newTask/newChild";
import Array from "./components/newTask/Array";
import ArrayMap from "./components/newTask/ArrayMap";
import Multiselect from "./components/newTask/multiSelect";
import UserDashboard from './components/User/dashboard';
import '../src/components/assets/img/login.jpg';
import ForgetPswd from './components/User/forgetPswd';
import Cart from './components/User/cart';
import Checkout from './components/User/checkout';
import Confirmation from './components/User/confirmation';
import Order from './components/User/order';


function App(args) {

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<UserLogin />} />
          <Route path="/admin/login" exact element={<Login />} />
          <Route path="/admin/product" exact element={<Product />} />
          <Route path="/admin/dashboard" exact element={<AdminPanel />} />
          {/* <Route path="/add" exact element={<CreateProduct />} /> */}
          {/* <Route path="/navbar" exact element={<NavbarTemplate />} /> */}
          <Route path="/sidebar" exact element={<SideBarTemplate />} />
          {/* <Route path="/maincontent" exact element={<MainContent />} /> */}
          {/* <Route path="/edit" exact element={<EditProduct />} /> */}
          <Route path="/logout" exact element={<LogOut />} />
          {/* <Route path="/api" element={<NewAPI />} /> */}
          <Route path="/home" element={<HomePage />} />
          {/* <Route path="/parent" element={<ParentComponent />} /> */}
          {/* <Route path="/child" element={<ChildComponent />} /> */}
          {/* <Route path="/newchild" element={<NewChildComponent />} /> */}
          <Route path="/completed" element={<Completed />} />
          {/* <Route path="/array" element={<Array />} /> */}
          <Route path="/admin/bundle" element={<Bundle />} />
          {/* <Route path="/addbundle" element={<CreateBundle />} /> */}
          {/* <Route path="/addcampaign" element={<CreateCampaign />} /> */}
          {/* <Route path="/arraymap" element={<ArrayMap />} /> */}
          {/* <Route path="/multi" element={<Multiselect />} /> */}
          {/* <Route path="/forget" element={<ForgetPswd />} /> */}
          <Route path="/admin/campaign" element={<Campaign />} />
          <Route path="/editcampaign" element={<EditCampaign />} />
          <Route path="/user/signup" element={<Signup />} />
          <Route path="/user/dashboard" element={<UserDashboard />} />
          <Route path="/user/cart" element={<Cart/>} />
          <Route path="/user/checkout" element={<Checkout/>} />
          <Route path="/user/order" element={<Order />} />
          <Route path="/user/confirmation" element={<Confirmation />} />

        </Routes>
      </div>
    </Router>
  );


}
export default App;
