import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "reactstrap";

const LogOut = () => {
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.clear();
    navigate("/admin/login");
  }

  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email === null) {
      navigate('/admin/login');
    }
  }, [])

  return (
    <Button color='danger' onClick={handleLogOut}>Logout</Button>
  );
}
export default LogOut;