import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, Label, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const CreateProduct = (props) => {
    // useState
    const token = localStorage.getItem('token');
    const [data, setdata] = useState({ name: "", img: [], dets: "", type: "", price: 0 });
    const [errPrice, setErrPrice] = useState("");
    const [errType, setErrType] = useState("");
    const [errImg, setErrImg] = useState("");
    const [errDets, setErrDets] = useState("");
    const navigate = useNavigate();
    const [errName, setErrName] = useState("");
    const [modal, setModal] = useState(false);
    const [message, setMessage] = useState("");
    const createProductUrl = process.env.React_App_API_URL + '/product/createProduct';

    //functions
    const toggle = () => {
        setModal(!modal)
        if (modal === false) {
            setErrDets("");
            setErrImg("");
            setErrName("");
            setErrType("");
            setMessage("");
            setErrPrice("");
        }
    }

    const handleChange = (event) => {
        let name = event.target.name;
        let val = event.target.value;
        setdata({
            ...data,
            [name]: val,
        })
    }

    const handleSubmit = () => {
        postData();
        data.reset()
    }


    useEffect(() => {
        props.getData();
    }, [data])

    const handleImg = (e) => {
        console.log("target.files", e.target.files[0]);
        setdata({
            ...data, img: e.target.files[0]
        })
    }

    //to create product
    const postData = () => {
        console.log("img", data.img);
        axios.post(createProductUrl,
            {
                prodDetails: data.dets,
                productName: data.name,
                productType: data.type,
                productImg: data.img,
                productPrice: data.price
            }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data '
            }
        })
            .then((response) => {
                console.log(response.data);
                if (response.status === 200) {
                    setdata({ name: "", img: [], dets: "", type: "", price: 0 });
                }
                props.getData();
                toggle();
            })
            .catch(error => {
                console.log(error);
                let err = error.response.data.errors;
                console.log(err, "err");
                err.filter((val) => {
                    if (val.param === "productName") {
                        setErrName(val.msg);
                    }
                    if (val.param == "productImg") {
                        setErrImg(val.msg);
                    }
                    if (val.param === "prodDetails") {
                        setErrDets(val.msg);
                    }
                    if (val.param === "productType") {
                        setErrType(val.msg);
                    }
                    if (val.param === "productPrice") {
                        setErrPrice(val.msg);
                    }
                })
            })
    }

    const isLogggedIn = () => {
        const email = localStorage.getItem('email');
        if (email === null) {
            navigate('/admin/login');
        }
    }

    useEffect(() => {
        isLogggedIn();
    }, [])


    return (<div>

        <Button className="btn btn-sm btn-primary shadow-sm" color="primary" onClick={toggle}><i className="fas fa-plus fa-sm text-white-50"></i> Add Product</Button>

        < Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Add a New Product!</ModalHeader>
            <ModalBody>

                <Form className="form">
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label" for="product_name">PRODUCT NAME</label>
                                <Input id="product_name" name="name" placeholder="PRODUCT NAME" className="form-control Input-md" required="" type="text" onChange={handleChange} />
                                {errName && <span style={{ color: "red" }}>{errName}</span>}

                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label" for="product_categorie">PRODUCT TYPE</label>
                                <Input id="product_type" name="type" type="text" placeholder="PRODUCT TYPE" onChange={handleChange} className="form-control Input-md" required="" />
                                {errType && <span style={{ color: "red" }}>{errType}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label" for="available_quantity">PRODUCT DETAILS</label>
                                <Input id="product_details" name="dets" placeholder="PRODUCT DETAILS" className="form-control Input-md" required="" type="text" onChange={handleChange} />
                                {errDets && <span style={{ color: "red" }}>{errDets}</span>}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label" for="product_weight">PRODUCT IMAGE</label>
                                <Input id="product_image" placeholder="PRODUCT IMAGE" className="form-control Input-md" required="" type="file" accept="image/png, image/jpg, image/jpeg" name="img" onChange={(e) => handleImg(e)} />
                                {errImg && <span style={{ color: "red" }}>{errImg}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label" for="product_price">PRODUCT PRICE</label>
                                <Input id="product_price" name="price" placeholder="PRODUCT PRICE" className="form-control Input-md" required="" type="number" onChange={handleChange} />
                                {errPrice && <span style={{ color: "red" }}>{errPrice}</span>}

                            </div>
                        </div>
                        </div>
                    <p style={{ color: "red" }}>{message}</p>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button type="button" className="btn btn-danger" onClick={toggle} data-dismiss="modal">Close</Button>
                <Button id="singlebutton" color="primary" name="singlebutton" className="btn btn-primary" onClick={handleSubmit}>submit</Button>
            </ModalFooter>
        </Modal>


    </div>);

}
export default CreateProduct;










