import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Form, FormGroup, Label, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const CreateBundle = (props) => {
    // useState
    const [createdBy, setcreatedBy] = useState("");
    const [selectContent, setSelectContent] = useState("");
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    const [data, setdata] = useState({ name: "", create: createdBy, desc: "", max: 0, content: selectContent });
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const adminID = localStorage.getItem('adminID');
    const [productData, setProductData] = useState([]);
    const contentOptions = [];
    const createdOptions = [{ label: email, value: adminID }];
    const [errMax, setErrMax] = useState("");
    const [errContent, setErrContent] = useState("");
    const [errCreate, setErrCreate] = useState("");
    const [errDesc, setErrDesc] = useState("");
    const [errName, setErrName] = useState("");
    const [message, setMessage] = useState("");
    const createArray = [];
    const getProductUrL = process.env.React_App_API_URL+'/product/getAllProduct?limit=1000';
    const makeBundleUrl = process.env.React_App_API_URL+'/bundle/createBundle';

    //functions

    const toggle = () => {
        setModal(!modal);
        if (modal === false) {
            setErrMax("");
            setErrCreate("");
            setErrName("");
            setErrDesc("");
            setMessage("");
            setErrContent("");
            setSelectContent("");
        }
    }

    const handleChange = (event) => {
        let name = event.target.name;
        let val = event.target.value;
        data.create = createdBy;
        data.content = selectContent;
        data.content = selectContent;
        setdata({
            ...data,
            create: createdBy,
            content: selectContent
        })
        setdata({
            ...data,
            [name]: val,
        })
    }

    const getProduct = () => {
        axios.get(getProductUrL, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(function (response) {
            let data = response.data.data;
            setProductData(data);
        })
    }

    const handleSubmit = () => {
        postData();
        data.reset();
    }

    //to create bundle
    const postData = () => {

        console.log("createdBy", createdBy);
        console.log("Bundle", data);

        let params = {
            bundleName: data.name,
            createdBy: createdBy,
            maxPurchase: data.max,
            content: selectContent,
            description: data.desc
        }

        axios.post(makeBundleUrl,
            params, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                console.log(response.data);
                if (response.status === 200) {
                    setdata({ name: "", create: createdBy, desc: "", max: 0, content: "" });
                    setcreatedBy("");
                    setSelectContent("");
                }
                props.getData();
                toggle();
            })
            .catch(error => {
                console.log(error);
                let err = error.response.data.errors;
                console.log(err, "err");

                err.filter((val) => {
                    if (val.param === "bundleName") {
                        setErrName(val.msg);
                    }
                    if (val.param === "description") {
                        setErrDesc(val.msg);
                    }
                    if (val.param === "createdBy") {
                        setErrCreate(val.msg);
                    }
                    if (val.param === "content") {
                        setErrContent(val.msg);
                    }
                    if (val.param === "maxPurchase") {
                        setErrMax(val.msg);
                    }
                })

            })
    }

    const handleCreateBy = (e) => {
        console.log("create in func", e.value);
        createArray.push(e.value);
        setcreatedBy(createArray);
        setdata({
            ...data,
            create: createArray
        })
    }

    const isLoggedIn = () => {
        const email = localStorage.getItem('email');
        if (email === null) {
            navigate('/admin/login');
        }
    }

    useEffect(() => {
        isLoggedIn();
    }, [])

    function handleContent(e) {
        let x = e.map((val) => {
            return (val.value
            )
        })
        console.log(x, "x");
        setSelectContent(x);
        setdata({
            ...data,
            content: x
        })
        console.log("multiSelect", e);
    }


    useEffect(() => {
        props.getData();
        getProduct();
    }, [data]);

    productData.map((info) => {
        contentOptions.push({
            label: info.productName,
            value: info._id
        }
        )
        return true;
    }
    )

    return (<div>

        <Button className="btn btn-sm btn-primary shadow-sm"  color="primary" onClick={toggle}><i className="fas fa-plus fa-sm text-white-50"></i> Add Bundle</Button>

        < Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Add a New Bundle!</ModalHeader>
            <ModalBody>

            <Form className="form">
                {/* <!-- Text Input--> */}
                <div className="row">
                  {/* <!-- Area Chart --> */}
                  <div className="col-4">
                    <div className="form-group">

                      <label className="control-label" for="name">BUNDLE NAME:</label>
                      <Input id="BUNDLE_NAME" type="text" name="name" placeholder="Enter Bundle Name" onChange={handleChange} className="form-control Input-md" required="" />
                      {errName && <span style={{ color: "red" }}>{errName}</span>}
                    </div>
                  </div>

                  <div className="col-4">
                    {/* <!-- Select Basic --> */}
                    <div className="form-group">
                      <label className="control-label" for="create">CREATED BY:</label>
                      <Select id="product_categorie" name="product_categorie" options={createdOptions} onChange={(e) => handleCreateBy(e)} className="form-control">
                      </Select>
                      {errCreate && <span style={{ color: "red" }}>{errCreate}</span>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">

                      <label className="control-label" for="desc">DESCRIPTION:</label>
                      <Input id="description" name="desc" placeholder="Enter Description" onChange={handleChange} className="form-control Input-md" required="" type="text" />
                    </div>
                    {errDesc && <span style={{ color: "red" }}>{errDesc}</span>}
                  </div>
                  <div className="col-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">

                      <label className="control-label" for="max">MAX PURCHASE</label>
                      <Input id="max_purchase" name="max" type="number" placeholder="Enter Max Purchase Number" onChange={handleChange} className="form-control Input-md" required="" />
                      {errMax && <span style={{ color: "red" }}>{errMax}</span>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    {/* <!-- Text Input--> */}
                    <div className="form-group">

                      <label className="control-label" for="product_description">CONTENT</label>
                      <Select isMulti id="content" name="content" options={contentOptions} onChange={(e) => handleContent(e)} className="form-control">
                      </Select>
                      {errContent && <span style={{ color: "red" }}>{errContent}</span>}
                    </div>
                  </div>
                </div>
                <p style={{ color: "red" }}>{message}</p>
              </Form>
            </ModalBody>
            <ModalFooter>
            <Button type="Button" className="btn btn-danger" onClick={toggle} data-dismiss="modal">Close</Button>
              <Button id="singleButton" name="singleButton" color="primary" onClick={handleSubmit} className="btn btn-primary">submit</Button>
           
            </ModalFooter>
        </Modal>

    </div >);

}
export default CreateBundle;




